import {makeStyles} from "@material-ui/core";
import {useThemeValues} from "hooks/useThemeValues";
import theme from "constants/themes/theme";

export const tipSelectionStyles = makeStyles(() => {
  const {translucentPrimaryColor} = useThemeValues();

  return {
    tipSelectionWrapper: {
      fontFamily: theme.fonts.Inter,
      display: "flex",
      flexDirection: "column",
      padding: "16px 16px 6px 16px",
      color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
    },
    flexBoxSpaceBetween: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tipTextHeader: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: "17px",
      marginBottom: 8,
    },
    tipsButtonsWrapper: {
      display: "flex",
      alignItems: "center",
      gap: 8,
      marginBottom: 8,
      height: 35,
    },
    tipToggleButton: {
      fontFamily: theme.fonts.Inter,
      backgroundColor: translucentPrimaryColor,
      flexGrow: 1,
      height: 32,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderWidth: 0,
    },
    tipActiveToggleButton: {
      fontFamily: theme.fonts.Inter,
      backgroundColor: translucentPrimaryColor,
      flexGrow: 1,
      height: 27,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 12px",
      borderWidth: 4,
      boxSizing: "content-box",
    },
    recurringTipText: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
    addTipModalWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "0px 16px",
      color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
      fontFamily: theme.fonts.Inter,
      height: "calc(100% - 67px)",
    },
    addTipContent: {
      display: "flex",
      flexDirection: "column",
    },
    imageWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 12,
      marginBottom: 16,
    },
    addTipDescriptionText: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "17px",
      marginBottom: 16,
    },
    textField: {
      color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
      fontSize: 18,
      lineHeight: "125%",
      fontWeight: 400,
    },
    inputAdornment: {
      marginRight: 8,
      color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
      fontSize: 18,
      lineHeight: "125%",
      fontWeight: 400,
    },
    updateTipButton: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.48px",
      textTransform: "capitalize",
      margin: "16px 0px",
      height: 62,
    },
    inputTypeNum: {
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  };
});
