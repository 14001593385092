import {DeviceStatuses} from "../types";

export const SELF_SERVE_REDUCER = "SELF_SERVE";
export const BUSINESS_THEME_THUNK = "BUSINESS_THEME_THUNK";

export const DRYER_PREFIX = "D";
export const WASHER_PREFIX = "W";

export const DEVICE_STATUSES_DISPLAY = {
  [DeviceStatuses.ONLINE]: "Available",
  [DeviceStatuses.IN_USE]: "In use",
  [DeviceStatuses.OFFLINE]: "Device not found",
  [DeviceStatuses.PENDING_START]: "Pending Start",
  [DeviceStatuses.COMPLETED]: "Completed",
};
