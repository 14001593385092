import React, {useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {Box, Flex, Image, Text} from "rebass/styled-components";
import {A11yButton} from "components/common/buttons/A11yButton";
import PaymentMethodList from "components/payment/PaymentMethodList";
import {useThemeValues} from "hooks/useThemeValues";
import {STRIPE_PUBLIC_KEY} from "utils/config";
import {getCreditCardBrandIcon} from "utils/payment";
import {EN_LOCALE} from "locales/en";
import {PaymentRequired, RightChevronIcon} from "assets/images";
import {sectionStyles} from "../styles";
import moduleStyles from "./payment.module.scss";


const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const Payment = (props) => {
  const {
    customer,
    onPaymentSelection,
    onShowNewPaymentMethod,
    setShowPaymentMethods,
    showPaymentMethods,
    customerPaymentMethods,
    paymentToken,
    showHeader = true,
    isInvoicingOrder,
    totalAmount,
    businessName,
    machine,
    order,
  } = props;
  const [paymentMethods, setPaymentMethods] = useState(() =>
    customerPaymentMethods
      ? customerPaymentMethods
      : customer
      ? customer.paymentMethods
      : null
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const {translucentPrimaryColor} = useThemeValues();
  const [digitalWalletSelectionType, setDigitalWalletSelectionType] = useState(null);

  useEffect(() => {
    if (customerPaymentMethods?.length && paymentToken) {
      const newState =
        customerPaymentMethods.find((pm) => pm.paymentMethodToken === paymentToken) || {};

      Object.keys(newState).length && setSelectedPaymentMethod(newState);
    }
  }, [customerPaymentMethods, paymentToken]);

  useEffect(() => {
    const pmList = customerPaymentMethods
      ? customerPaymentMethods
      : customer
      ? customer.paymentMethods
      : null;
    setPaymentMethods(pmList);
    setSelectedPaymentMethod(
      pmList?.length > 0 && paymentToken
        ? pmList.find((pm) => pm.paymentMethodToken === paymentToken)
        : null
    );
  }, [customer, customerPaymentMethods, paymentToken]);

  const showCardWarningImage = !selectedPaymentMethod?.last4 && !isInvoicingOrder;

  const onPaymentsClick = () => {
    setShowPaymentMethods(true);
  };

  const handleDigitalWalletSelection = (digitalWalletSelection) => {
    setDigitalWalletSelectionType(digitalWalletSelection);
  };

  return (
    <Box>
      {showHeader && (
        <Box {...styles.section.header} bg={translucentPrimaryColor}>
          Payment
        </Box>
      )}
      <A11yButton
        onAction={isInvoicingOrder ? null : onPaymentsClick}
        className={moduleStyles.item}
        aria-label={EN_LOCALE.label.changePaymentMethod}
      >
        <Box width="34px" mr="12px" alignItems="center" display="flex">
          <Image
            src={getCreditCardBrandIcon(
              isInvoicingOrder
                ? null
                : digitalWalletSelectionType
                ? digitalWalletSelectionType
                : selectedPaymentMethod?.brand
            )}
          />
        </Box>
        <Flex {...styles.section.link.dataWrapper}>
          {isInvoicingOrder ? (
            <Box {...styles.section.link.data}>Marked for Invoicing</Box>
          ) : (
            <>
              <Box {...styles.section.link.data}>
                Payment Method
                <Text {...styles.section.link.dataSubText}>
                  {selectedPaymentMethod?.last4 ? (
                    `.... ${selectedPaymentMethod?.last4}`
                  ) : (
                    <i>Add payment method</i>
                  )}
                </Text>
              </Box>
            </>
          )}

          <Flex {...styles.section.link.imagesConatiner}>
            {showCardWarningImage ? (
              <Image src={PaymentRequired} {...styles.section.link.paymentRequired} />
            ) : null}
            {!isInvoicingOrder && (
              <Image src={RightChevronIcon} {...styles.section.link.rightChevron} />
            )}
          </Flex>
        </Flex>
      </A11yButton>
      <Elements stripe={stripePromise}>
        <PaymentMethodList
          isDockOpen={showPaymentMethods}
          onClose={() => {
            setShowPaymentMethods(!showPaymentMethods);
          }}
          customer={customer}
          onSave={(pm, paymentMethods) => {
            setSelectedPaymentMethod(pm);
            setPaymentMethods(paymentMethods);
            setShowPaymentMethods(false);
            onPaymentSelection(pm.paymentMethodToken);
          }}
          newOrder={true}
          paymentMethodList={paymentMethods}
          paymentMethod={selectedPaymentMethod}
          onNewPaymentMethod={() => {
            onShowNewPaymentMethod();
          }}
          totalAmount={totalAmount}
          businessName={businessName}
          machine={machine}
          order={order}
          onDigitalWalletSelection={handleDigitalWalletSelection}
        />
      </Elements>
    </Box>
  );
};

const styles = {
  section: sectionStyles,
};

export default Payment;