export const DICTIONARY = {
  CARE_PREFERENCES: "Care Preferences",
  SAVE_PREFERENCES: "Save Preferences",
  PREFERENCES_NOTES: "Preferences Notes",
  PREFERENCES_NOTES_PLACEHOLDER:
    "e.g. Hypoallergenic, please use bleach for whites, etc.",
  HANG_DRY: "Hang Dry",
  HANG_DRY_INSTRUCTIONS: "Hang Dry Instructions",
  HANG_DRY_INSTRUCTIONS_PLACEHOLDER:
    "e.g. Please hang the 4 collared shirts I included in the black bag.",
  SAVE_PREFERENCES_ERROR: "Failed to save preferences.",
  FETCH_STORE_ERROR: "Failed to fetch store data.",
};
