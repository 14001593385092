import {makeStyles} from "@material-ui/styles";
import {useThemeValues} from "hooks/useThemeValues";
import {TRANSITION_TIME} from "constants/order";

export const useThemeToWrappedField = (customColor?: string) => {
  const {primaryColor} = useThemeValues();
  const colorToSet = customColor || primaryColor;

  const useStyles = makeStyles({
    field: {
      transition: `${TRANSITION_TIME}ms`,
      "&.active": {borderColor: colorToSet, boxShadow: `0 0 1px 0.5px ${colorToSet}`},
      "&.active .type-input": {
        color: `${colorToSet}`,
      },
    },
  });
  const classes = useStyles();
  return classes.field;
};
