import {FC} from "react";
import {ButtonVariant, Button} from "components/common/buttons/Button";
import styles from "./orderNotes.module.scss";

export const OrderNotes: FC<{notes: string; onEdit: () => void}> = ({notes, onEdit}) => {
  return (
    <div className={styles.orderNotesWrapper}>
      <div>
        <h3>Order notes</h3>
        <Button
          variant={ButtonVariant.UNDERLINED}
          className={styles.editButton}
          onClick={onEdit}
        >
          Edit
        </Button>
      </div>
      {notes && <p>{notes}</p>}
    </div>
  );
};
