import {useCallback} from "react";
import {displayPromotionAmount} from "components/order-summary/utils";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {getCustomerSelectedServices} from "state/redux/slices/order/selectors/servicesSelector";
import {getStoreId} from "state/redux/slices/order/selectors/storeSelectors";
import {createErrorToast} from "utils/notifications/createErrorToast";
import {createSuccessToast} from "utils/notifications/createSuccessToast";
import {validateBusinessPromo} from "api/promotions";

export const usePromoCode = () => {
  const dispatch = useAppDispatch();
  const storeId = useAppSelector(getStoreId);
  const customerSelectedServices = useAppSelector(getCustomerSelectedServices);

  const validatePromoCode = useCallback(
    async ({
      promoName,
      isServiceChanged,
    }: {
      promoName: string;
      isServiceChanged: boolean;
    }) => {
      const servicePriceIds = customerSelectedServices.map(
        (service) => service.prices[0].id
      );

      try {
        const validation = await Promise.any(
          servicePriceIds.map((id) =>
            validateBusinessPromo({
              storeId: storeId,
              servicePriceId: id,
              promoCode: promoName,
            })
          )
        );

        const isAnyServicesValid = validation.data.success;
        if (isAnyServicesValid) {
          const {type, amount} = validation.data;

          const promoAmountString = displayPromotionAmount({
            promotionType: type,
            discountValue: amount,
          });
          const preparedPromoAmount = `-${promoAmountString}`;

          dispatch(orderActions.setPromoCodeAmount(preparedPromoAmount));
          dispatch(orderActions.setPromoCodeName(promoName));

          if (!isServiceChanged) {
            createSuccessToast({
              primaryMessage: promoName,
              secondaryMessage: "promo applied",
            });
          }

          return true;
        }
      } catch (error: any) {
        const { errors = [] } = error;
        const errorMessage = errors[0]?.response?.data?.error;
        
        createErrorToast({
          primaryMessage: errorMessage ?? "The promotion code is invalid or does not exist",
        });

        dispatch(orderActions.setPromoCodeAmount(null));
        dispatch(orderActions.setPromoCodeName(null));
        return false;
      }
    },
    [customerSelectedServices, dispatch, storeId]
  );

  return {validatePromoCode};
};
