import httpClient from "../httpClient";
import {ORDER_DELIVERY_TYPES} from "constants/order";
import {DeliveryWindow} from "types/deliveries/deliveryDay";
import {ObjectValues} from "types/typeUtils";
import {AxiosPromise} from "axios";

interface IGetOnDemandDeliveryEstimate {
  storeId: number;
  customerAddressId: number;
  deliveryWindow: DeliveryWindow;
  type: ObjectValues<typeof ORDER_DELIVERY_TYPES>;
  serviceOrderId?: number;
}

export type OnDemandEstimateResponse = {
  estimateId: number;
  pickupTime: string;
  deliveryTime: string;
  estimateFee: number; // in cents
  providerName: string;
  providerLogo: string;
};

type GetOnDemandDeliveryEstimate = (
  data: IGetOnDemandDeliveryEstimate
) => AxiosPromise<OnDemandEstimateResponse>;

export const getOnDemandDeliveryEstimate: GetOnDemandDeliveryEstimate = ({
  storeId,
  customerAddressId,
  deliveryWindow,
  type,
  serviceOrderId,
}) => {
  return httpClient({
    method: "POST",
    url: "/deliveries/estimate",
    data: {
      storeId,
      customerAddressId,
      deliveryWindow,
      type,
      serviceOrderId,
    },
  });
};
