import {FC, ReactNode} from "react";
import cn from "classnames";
import styles from "./section.module.scss";

export const Section: FC<{
  title: string;
  children: ReactNode;
  separators?: boolean;
  action?: ReactNode;
}> = ({title, children, separators, action}) => {
  return (
    <section className={styles.section}>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{title}</h2>
        {action}
      </div>
      <div className={cn(styles.content, {[styles.separators]: separators})}>
        {children}
      </div>
    </section>
  );
};
