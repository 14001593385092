import {Ref, useEffect, useRef} from "react";
import {Input, InputProps} from "@rebass/forms/styled-components";
import InputMask, {Props} from "react-input-mask";
import collapseSpaceBetweenChars from "utils/collapseSpaceBetweenChars";

type MaskedInputProps = Omit<InputProps, "style"> & {
  sx?: Record<string, string[]>;
  maskProps?: any;
  isFocused?: boolean;
  customRef?: Ref<HTMLInputElement>;
  maskChar?: string;
} & Omit<Props, "style" | "width">;

const MaskedInput = ({
  onChange,
  mask,
  maskChar = "_",
  value = "",
  sx = {},
  maskProps = {},
  isFocused = true,
  customRef,
  ...rest
}: MaskedInputProps) => {
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    isFocused ? inputRef?.current?.focus() : inputRef?.current?.blur();
  }, []);

  const beforeMaskedValueChange = (
    newState: {
      value: string;
      selection: {start: number; end: number} | null;
    },
    previousState: {value: string}
  ) => {
    const {value, selection} = newState;
    const {value: previousValue} = previousState;

    return collapseSpaceBetweenChars(
      maskChar,
      mask as string,
      value,
      selection,
      previousValue
    );
  };

  return (
    <InputMask
      alwaysShowMask
      onChange={onChange}
      beforeMaskedValueChange={beforeMaskedValueChange}
      value={value}
      mask={mask}
      maskChar={maskChar}
      {...maskProps}
    >
      {(inputProps: JSX.IntrinsicAttributes & InputProps) => (
        <Input
          {...styles.input}
          {...inputProps}
          sx={{...styles.input.sx, ...sx}}
          ref={customRef || inputRef}
          {...rest}
        />
      )}
    </InputMask>
  );
};

const styles = {
  input: {
    width: ["100%", "100%", "100%", "50%"],
    fontWeight: 600,
    sx: {
      textAlign: ["center", "center", "center", "center"],
      fontSize: "2rem",
      border: "none",
      outline: "none",
    },
  },
};

export default MaskedInput;
