import {useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {toast} from "react-toastify";
import {Box, Flex, Text} from "rebass/styled-components";
// Components
import {ApplyPromoWithDockModal} from "components/newOrder/common/ApplyPromo";
import EditServiceSelection from "components/newOrder/common/EditServiceSelection/EditServiceSelection";
import OrderNotesDockModal from "components/newOrder/common/OrderNotesForm/OrderNotesDockModal";
import {
  errorToastOptions,
  successToastOptions,
  ToastError,
  ToastSuccess,
} from "components/newOrder/common/Toast";
// Utils/Config
import {SERVICE_CATEGORY_TYPES} from "components/online-order/constants";
import {ERROR_MESSAGES} from "components/online-order/online-order-form/errorMessages";
import {sectionStyles} from "components/online-order/online-order-form/finishing-up/styles.js";
import PickupAndDeliverySummary from "components/online-order/online-order-form/finishing-up/summary/PickupAndDeliverySummary";
import {getServicePriceBreakdown} from "components/online-order/utils";
import {displayPromotionAmount} from "components/order-summary/utils";
import {referralSelectors} from "components/referral/redux";
import {useOrderConfig} from "hooks/newOrder/useOrderConfig";
import {useThemeValues} from "hooks/useThemeValues";
import useToggle from "hooks/useToggle";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {businessSelectors} from "state/redux/slices/business";
// Global state
import {orderActions, orderSelectors} from "state/redux/slices/order";
import {formatCustomerPreferences} from "utils/formatters/formatCustomerPreferences";
import {openView} from "utils/order/openView";
// APIs
import {fetchPreferences} from "api/online-order";
// Styles
import {validateBusinessPromo} from "api/promotions";
import {VIEWS, modifiersPricingTypeLabels} from "constants/order";
import "./styles.scss";

/**
 * @deprecated Details: https://cents.atlassian.net/browse/DEL-725
 *
 * Use `src/components/newOrder/routes/checkout/online-order-form/finishing-up/SummarySection` instead
 */
const Summary = (props) => {
  const {services, isInvoicingOrder} = props;

  const history = useHistory();
  const {url} = useRouteMatch();
  const dispatch = useAppDispatch();
  const {translucentPrimaryColor} = useThemeValues();
  const businessSettings = useAppSelector(businessSelectors.getBusinessSettingsFromState);
  const {
    nearStoresData: {data: availableStores},
    schedule: {subscription},
    orderBuilder: {
      store: {storeId},
      promo: {promoCodeAmount, promoCodeName},
      notes: {orderNotes, hangDryInstructions, customerNotes},
      services: {selectedModifiers, selectedService, selectedCategories},
    },
  } = useAppSelector(orderSelectors.getOnlineOrderData);
  const {referredByCodeName, referralPromoCodeAmount} = useAppSelector(
    referralSelectors.getApplyReferralInfo
  );

  const [priceString, setPriceString] = useState(null);
  const [selectedPreferenceNames, setSelectedPreferenceNames] = useState(null);

  const {isOpen: showServiceSelection, toggle: toggleShowServiceSelection} = useToggle();
  const {isOpen: showOrderNotesModal, toggle: toggleShowOrderNotesModal} = useToggle();

  const businessId = businessSettings.businessId;
  const servicePriceId = selectedService?.prices?.[0]?.id;

  const hasDryCleaning = selectedCategories.find(
    (category) => category.name === SERVICE_CATEGORY_TYPES.DRY_CLEANING
  );
  const {maxLaundryTurnAround} = useOrderConfig();

  const isRecurringSubscriptionEnabled = !!subscription?.interval;

  const recurringSubscriptionDiscount =
    availableStores?.ownDeliveryStore?.recurringDiscountInPercent ||
    availableStores?.onDemandDeliveryStore?.recurringDiscountInPercent;

  const isShowingRecurringDiscount =
    isRecurringSubscriptionEnabled &&
    Boolean(recurringSubscriptionDiscount) &&
    !availableStores?.availableServices?.isCommercialPricingTier;

  /**
   * Remove dry cleaning
   */
  const removeDryCleaningSelection = () => {
    dispatch(orderActions.setGlobalTurnAround(maxLaundryTurnAround));
    dispatch(orderActions.setIsDryCleaningCategorySelected(false));
  };

  const handleOrderNotesChange = (orderNotes) => {
    dispatch(orderActions.setOrderNotes(orderNotes));
    toggleShowOrderNotesModal();
  };

  const openPreferences = () => {
    dispatch(orderActions.setStage(VIEWS.PREFERENCES));
    openView({
      history,
      url,
      viewPath: "preferences",
      prevViewPath: "checkout",
      location: {
        prevRoute: "checkout",
      },
    });
  };

  useEffect(() => {
    // We should not send the validate request if this is the referral promo code
    if (selectedService && referredByCodeName !== promoCodeName) {
      const priceBreakdownResponse = getServicePriceBreakdown(selectedService);
      setPriceString(priceBreakdownResponse.priceString);
      if (promoCodeName) {
        validatePromoCode({promoName: promoCodeName, isServiceChanged: true});
      }
    }
  }, [selectedService, promoCodeName, referredByCodeName]);

  useEffect(
    function checkReferralCode() {
      if (referredByCodeName && !promoCodeName) {
        dispatch(orderActions.setPromoCodeName(referredByCodeName));
      }
    },
    [promoCodeName, referredByCodeName, dispatch]
  );

  useEffect(() => {
    async function initPreferences() {
      try {
        const hangDryCustomerInstructions = hangDryInstructions;
        const customerPrefsResp = await fetchPreferences(businessId);
        if (customerPrefsResp.data.success) {
          const formattedPreferences = formatCustomerPreferences({
            preferences: customerPrefsResp.data.preferences,
            hangDryCustomerInstructions,
            customerNotes,
          });

          setSelectedPreferenceNames(formattedPreferences);
        }
      } catch (e) {
        toast.error(ERROR_MESSAGES.GENERIC_ERROR_MSG);
      }
    }

    initPreferences();
  }, [businessId, customerNotes, hangDryInstructions]);

  const validatePromoCode = async ({promoName, isServiceChanged}) => {
    try {
      const response = await validateBusinessPromo({
        storeId: storeId,
        servicePriceId: servicePriceId,
        promoCode: promoName,
      });

      if (response.data.success) {
        const {type, amount} = response.data;

        const promoAmountString = displayPromotionAmount({
          promotionType: type,
          discountValue: amount,
        });
        const preparedPromoAmount = `-${promoAmountString}`;

        dispatch(orderActions.setPromoCodeAmount(preparedPromoAmount));
        dispatch(orderActions.setPromoCodeName(promoName));

        if (!isServiceChanged) {
          toast.success(
            <ToastSuccess primaryMessage={promoName} secondaryMessage="promo applied" />,
            successToastOptions
          );
        }

        return true;
      }
    } catch (error) {
      const errorMsg = isServiceChanged
        ? `${promoName} not applicable for selected service`
        : error?.response?.data?.error;
      toast.error(
        <ToastError message={errorMsg || "Something went wrong"} />,
        errorToastOptions
      );
      dispatch(orderActions.setPromoCodeAmount(null));
      dispatch(orderActions.setPromoCodeName(null));
      return false;
    }
  };

  return (
    <Box>
      <Box {...styles.section.header} bg={translucentPrimaryColor}>
        Your Order
      </Box>
      <Flex {...styles.section.link.wrapper} {...styles.columnDisplay}>
        {hasDryCleaning && businessSettings?.dryCleaningEnabled && (
          <Flex
            {...styles.section.link.dataWrapper}
            {...styles.yourOrderDataWrapper}
            {...styles.bottomPadding}
            {...styles.columnDisplay}
          >
            <Flex {...styles.section.link.dataWrapper}>
              <Box {...styles.data}>Dry Cleaning</Box>
              <Box>
                <Text {...styles.servicePrice}>TBD</Text>
              </Box>
            </Flex>
            {selectedService && (
              <Box>
                <Text {...styles.editLink} onClick={removeDryCleaningSelection}>
                  Remove
                </Text>
              </Box>
            )}
          </Flex>
        )}
        {selectedService && (
          <Flex
            {...styles.section.link.dataWrapper}
            {...styles.yourOrderDataWrapper}
            {...styles.bottomPadding}
            {...styles.columnDisplay}
            {...styles.gap}
          >
            <Flex {...styles.section.link.dataWrapper} mb="4px">
              <Box {...styles.data}>{selectedService?.name || "No service selected"}</Box>
              <Box>
                <Text {...styles.servicePrice}>{priceString}</Text>
              </Box>
            </Flex>

            {selectedModifiers
              ? selectedModifiers.map((item) => (
                  <Flex
                    {...styles.section.link.dataWrapper}
                    {...styles.yourOrderText}
                    mb="4px"
                    key={item.id}
                  >
                    <Box>
                      <Text {...styles.yourOrderText}>{`+ ${item.modifier.name}`}</Text>
                    </Box>
                    <Box>
                      <Text {...styles.yourOrderText} {...styles.servicePrice}>
                        {`$${Number(item.modifier.price).toFixed(2)} / ${
                          modifiersPricingTypeLabels[item.modifier.pricingType] ?? "lb"
                        }`}
                      </Text>
                    </Box>
                  </Flex>
                ))
              : null}

            <Box>
              <Text {...styles.editLink} onClick={toggleShowServiceSelection}>
                Edit
              </Text>
            </Box>
          </Flex>
        )}
        <Flex {...styles.section.link.dataWrapper}>
          <Box {...styles.data} mt="6px">
            Order notes
          </Box>
          <Box {...styles.yourOrderHeader}>
            <Text {...styles.editLink} onClick={toggleShowOrderNotesModal}>
              Edit
            </Text>
          </Box>
        </Flex>

        <Flex {...styles.section.link.dataWrapper} {...styles.bottomPadding}>
          <Box>
            <Text {...styles.data} {...styles.yourOrderText} mt="4px">
              {orderNotes || <i>Add order notes</i>}
            </Text>
          </Box>
        </Flex>

        <Flex {...styles.section.link.dataWrapper}>
          <Box {...styles.data} mt="6px">
            Care preferences
          </Box>
          <Box {...styles.yourOrderHeader}>
            <Text {...styles.editLink} onClick={openPreferences}>
              Edit
            </Text>
          </Box>
        </Flex>

        <Flex {...styles.section.link.dataWrapper} {...styles.bottomPadding}>
          <Box>
            <Text {...styles.data} {...styles.yourOrderText} mt="4px">
              {selectedPreferenceNames}
            </Text>
          </Box>
        </Flex>

        <Flex {...styles.section.link.dataWrapper} pb="4px">
          <ApplyPromoWithDockModal
            currentPromoName={promoCodeName}
            onApply={validatePromoCode}
          />
        </Flex>
      </Flex>

      <PickupAndDeliverySummary />

      {isShowingRecurringDiscount && (
        <Flex {...styles.promoSection.wrapper} {...styles.bottomPadding}>
          <Text {...styles.promoSection.text}>Recurring Discount</Text>
          <Text {...styles.promoSection.text}>{recurringSubscriptionDiscount}%</Text>
        </Flex>
      )}

      {promoCodeAmount && (
        <Flex {...styles.promoSection.wrapper} {...styles.bottomPadding}>
          <Text {...styles.promoSection.text}>Promo applied</Text>
          <Text {...styles.promoSection.text}>{promoCodeAmount}</Text>
        </Flex>
      )}

      {!!referredByCodeName && promoCodeName === referredByCodeName && (
        <Flex {...styles.promoSection.wrapper} {...styles.bottomPadding}>
          <Text {...styles.promoSection.text}>Discount applied</Text>
          <Text {...styles.promoSection.text}>-{referralPromoCodeAmount}</Text>
        </Flex>
      )}

      {!isInvoicingOrder && (
        <Text {...styles.noteText}>
          Your card will be charged when your order is ready for delivery or when you pick
          up in store. You will receive a text message with a link to view your order.
        </Text>
      )}
      <EditServiceSelection
        services={services}
        isOpen={showServiceSelection}
        toggle={toggleShowServiceSelection}
      />
      <OrderNotesDockModal
        isOpen={showOrderNotesModal}
        toggle={toggleShowOrderNotesModal}
        onOrderNotesChange={handleOrderNotesChange}
        orderNotes={orderNotes || ""}
        readonly={false}
      />
    </Box>
  );
};

const styles = {
  section: sectionStyles,
  columnDisplay: {
    flexDirection: "column",
  },
  data: {
    fontSize: "14px",
    mr: "8px",
    minWidth: "130px",
    fontWeight: 600,
    color: "NEW_TEXT_PRIMARY_BLUE",
  },
  editLink: {
    fontWeight: 500,
    variant: "link",
    fontSize: "14px!important",
    mt: "4px",
    display: "block",
  },
  yourOrderDataWrapper: {
    alignItems: "flex-start",
  },
  yourOrderHeader: {
    fontWeight: 600,
    fontSize: ["14px"],
  },
  yourOrderText: {
    fontWeight: 400,
    fontSize: ["14px"],
    color: "NEW_TEXT_GREY",
    lineHeight: "17px",
  },
  alignItemsRight: {
    textAlign: "right",
  },
  serviceDetails: {
    mr: 0,
    minWidth: "70%",
  },
  servicePrice: {
    color: "NEW_TEXT_GREY",
    fontWeight: 700,
    flexShrink: 1,
    fontSize: "12px",
    minWidth: "30%",
  },
  bottomPadding: {
    pb: "16px",
  },
  noteText: {
    mx: "18px",
    marginBottom: "14px",
    fontSize: "12px",
    color: "NEW_TEXT_GREY",
  },
  promoSection: {
    wrapper: {
      marginTop: "-10px",
      justifyContent: "space-between",
      px: "18px",
    },
    text: {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "16px",
      color: "var(--theme-color)",
    },
  },
};

export default Summary;
