import {createSelector} from "reselect";
import {RootState} from "state/redux/store";

export const getBusinessState = (state: RootState) => state.business;
export const getBusinessId = createSelector(
  getBusinessState,
  (state) => state.businessId
);
export const getBusinessName = createSelector(
  getBusinessState,
  (state) => state.businessName
);
export const getBusinessTheme = createSelector(getBusinessState, (state) => state.theme);
export const getBusinessThemeFetchingStatus = createSelector(
  getBusinessState,
  (state) => state.apiRequests.businessTheme.fetchingStatus
);
export const getBusinessSettingsFromState = createSelector(
  getBusinessState,
  (state) => state.apiRequests.businessSettings.data
);
