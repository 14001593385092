import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button} from "@mui/material";
import {useFlags} from "launchdarkly-react-client-sdk";
import _ from "lodash";

import "./styles.scss";

import {DockModal} from "components/common";
import ServiceModifiers from "./ServiceModifiers";
import {getPriceString} from "components/online-order/utils";
import {orderItemCategories} from "components/order-summary/constants";

const ServiceModifiersDockModal = ({
  service,
  modifiers,
  selectedModifiersIds,
  isOpen,
  toggle,
  onSubmit,
}) => {
  const flags = useFlags();
  const [modifiersIds, setModifiersIds] = useState(selectedModifiersIds || []);

  const {
    name: serviceName,
    description: serviceDescription,
    serviceCategory,
    prices,
  } = service;

  const isServiceProvided = !!(serviceCategory && prices);

  const servicePrice = isServiceProvided ? getPriceString({serviceCategory, prices}) : "";
  const filteredModifiers = modifiers?.filter((modifier) => {
    return (
      flags.fixedPriceModifiers ||
      !modifier.pricingType ||
      modifier.pricingType === orderItemCategories.perPoundItem
    );
  });

  useEffect(() => {
    setModifiersIds(isOpen ? selectedModifiersIds : []);
  }, [isOpen, selectedModifiersIds]);

  const setServiceModifiersIds = (modifierId) => {
    if (modifiersIds?.includes(modifierId)) {
      const updatedModifiers = modifiersIds.filter(
        (data) => Number(data) !== Number(modifierId)
      );
      setModifiersIds([...updatedModifiers]);
    } else {
      setModifiersIds((state) => [...state, modifierId]);
    }
  };

  const handleSubmit = () => {
    onSubmit(_.clone(modifiersIds));
  };
  return (
    <DockModal isOpen={isOpen} toggle={toggle} header="Add-ons" size={0.93} showExitIcon>
      <main className="main-content">
        <ServiceModifiers
          serviceName={serviceName}
          servicePrice={servicePrice}
          serviceDescription={serviceDescription}
          modifiers={filteredModifiers}
          selectedModifiersIds={modifiersIds}
          onModifierClick={setServiceModifiersIds}
        />

        <div className="new-order-sticky-button popup-window">
          <div className="footer-button-wrapper">
            <Button
              color="primary"
              variant="contained"
              className="footer-button"
              onClick={handleSubmit}
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "28px",
                padding: "19px",
                textTransform: "capitalize",
              }}
              fullWidth
            >
              {modifiersIds.length ? "Save Add-ons" : "No Add-ons"}
            </Button>
          </div>
        </div>
      </main>
    </DockModal>
  );
};

ServiceModifiersDockModal.propTypes = {
  service: PropTypes.object.isRequired,
  modifiers: PropTypes.array.isRequired,
  selectedModifiersIds: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ServiceModifiersDockModal.defaultProps = {
  service: {},
  modifiers: [],
  selectedModifiersIds: [],
  isOpen: false,
  toggle: () => {},
  onSubmit: () => {},
};

export default ServiceModifiersDockModal;
