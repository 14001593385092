import {createSelector} from "reselect";
import {RootState} from "state/redux/store";

export const getOnlineOrderData = (state: RootState) => state.order;

export const getBusinessTheme = createSelector(
  getOnlineOrderData,
  (state) => state.initialOrderData.data.theme
);
export const getStoreTimeZone = createSelector(
  getOnlineOrderData,
  (state) => state.nearStoresData.data.deliveryDays[0]?.timeZone
);

export const getSubscriptions = createSelector(
  getOnlineOrderData,
  (state) => state.initialOrderData.data.subscriptions
);
export const getAddressTimeZone = createSelector(
  getOnlineOrderData,
  (state) => state.nearStoresData.data.addressTimeZone
);
export const getLatestAddress = createSelector(
  getOnlineOrderData,
  (state) => state.initialOrderData.data.customerAddress
);
export const getOrderInitialData = createSelector(
  getOnlineOrderData,
  (state) => state.initialOrderData
);
export const getNearStoresData = createSelector(
  getOnlineOrderData,
  (state) => state.nearStoresData
);
export const getCurrentView = createSelector(
  getOnlineOrderData,
  (state) => state.schedule.currentStage
);

export const getScheduleDetails = createSelector(
  getOnlineOrderData,
  (state) => state.schedule
);

export const getReturnWindowsState = createSelector(
  getOnlineOrderData,
  (state) => state.returnWindows
);

export const getStoreId = createSelector(
  getOnlineOrderData,
  (state) =>
    state.nearStoresData.data.onDemandDeliveryStore?.storeId ||
    state.nearStoresData.data.ownDeliveryStore?.storeId
);

/**
 * @deprecated Use src/state/redux/slices/business/selectors.ts instead
 */
export const getBusinessID = createSelector(
  getOnlineOrderData,
  (state) => state.initialOrderData.data.businessId
);

export const getCurrentCustomerAddress = createSelector(
  getOnlineOrderData,
  (state) => state.currentCustomerAddress
);

/**
 * @deprecated Use useFlags from Launchdarkly instead
 */
export const getLdFlags = createSelector(getOnlineOrderData, (state) => state.ldFlags);

export const getShowAddressDetailsModal = createSelector(
  getOnlineOrderData,
  (state) => state.isShowingAddressDetailsModal
);

export const getShowVerificationModal = createSelector(
  getOnlineOrderData,
  (state) => state.isShowingVerificationModal
);

export const getEditableAddress = createSelector(
  getOnlineOrderData,
  (state) => state.editableAddress
);

export const getTheme = createSelector(getOnlineOrderData, (state) => state.currentTheme);

/** @deprecated Use state/redux/slices/customer/selectors/credentialsSelector instead */
export const getCentsCustomerCredentials = createSelector(
  getOnlineOrderData,
  (state) => state.initialOrderData.data?.centsCustomerCredentials
);

export const getIsAutoScheduleReturn = createSelector(
  getOnlineOrderData,
  (state) => state.nearStoresData.data?.autoScheduleReturnEnabled
);

export const getTurnAround = createSelector(
  getOnlineOrderData,
  (state) => state.schedule.turnAround
);

export const getPricingTierId = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.store.pricingTierId
);

export const orderSelectors = {
  getOnlineOrderData,
  getStoreTimeZone,
  getBusinessTheme,
  getSubscriptions,
  getAddressTimeZone,
  getLatestAddress,
  getOrderInitialData,
  getNearStoresData,
  getCurrentView,
  getScheduleDetails,
  getReturnWindowsState,
  getStoreId,
  getBusinessID,
  getCurrentCustomerAddress,
  getLdFlags,
  getShowAddressDetailsModal,
  getShowVerificationModal,
  getTheme,
  getEditableAddress,
  /** @deprecated Use state/redux/slices/customer/selectors/credentialsSelector instead */
  getCentsCustomerCredentials,
  getIsAutoScheduleReturn,
  getTurnAround,
  getPricingTierId,
};
