import {Box, Button, Flex, Image, Text} from "rebass/styled-components";
import PropTypes from "prop-types";

import {ExitIcon} from "assets/images";

import {Loader} from "./Loader";
import {GLOBAL_STYLES} from "styles/globalStyles";
import theme from "constants/themes/theme";

const FullScreenModalForm = (props) => {
  const {
    onClose,
    header,
    btnLabel,
    footerBtnStyles,
    bodyStyle,
    children,
    onSubmit,
    disabled,
    loading,
  } = props;

  return (
    <Flex {...styles.wrapper}>
      <Image {...styles.closeIcon} src={ExitIcon} onClick={onClose} alt="Modal Close" />
      <Flex {...styles.header.wrapper}>
        <Text {...styles.header.text}>{header}</Text>
      </Flex>
      <Box {...styles.body.wrapper}>
        {loading && <Loader style={{height: "calc(var(--app-height) - 76px )"}} />}
        <Box {...styles.body.content} {...bodyStyle}>
          {children}
        </Box>
      </Box>
      <Flex {...styles.footer.wrapper}>
        <Button
          variant="primary"
          {...styles.footer.button}
          {...footerBtnStyles}
          onClick={onSubmit}
          disabled={disabled}
        >
          {btnLabel}
        </Button>
      </Flex>
    </Flex>
  );
};

const styles = {
  wrapper: {
    height: "var(--app-height)",
    flexDirection: "column",
    sx: {
      position: "relative",
    },
    maxWidth: GLOBAL_STYLES.MAX_WIDTH_CONTAINER,
    margin: "0 auto",
  },
  header: {
    wrapper: {
      alignItems: "center",
      justifyContent: "center",
      height: "76px",
      sx: {
        borderBottom: "1px solid",
        borderColor: "SEPERATOR_GREY",
      },
    },
    text: {
      fontWeight: 600,
      fontSize: "18px",
      color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
      fontFamily: theme.fonts.Inter,
    },
  },
  closeIcon: {
    height: "28px",
    width: "28px",
    sx: {
      position: "absolute",
      top: "24px",
      left: "16px",
      cursor: "pointer",
    },
  },
  body: {
    wrapper: {
      sx: {
        position: "relative",
      },
      height: "calc(var(--app-height) - 76px - 96px)",
    },
    content: {
      p: "16px",
      height: "calc(var(--app-height) - 76px - 96px)",
      pb: 0,
    },
  },
  footer: {
    wrapper: {
      mt: "auto",
      height: "62px",
      px: "16px",
      alignItems: "center",
      justifyContent: "center",
      mb: "16px",
    },
    button: {
      width: "100%",
      height: "100%",
      lineHeight: "28px",
      fontSize: "20px",
      fontWeight: 700,
      letterSpacing: "0.48px",
    },
  },
};

FullScreenModalForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  btnLabel: PropTypes.string,
  footerBtnStyles: PropTypes.object,
  bodyStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

FullScreenModalForm.defaultProps = {
  btnLabel: "Apply",
  footerBtnStyles: {},
  bodyStyle: {},
  disabled: false,
  loading: false,
};

export default FullScreenModalForm;
