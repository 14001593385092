import {Flex, Text, Image} from "rebass/styled-components";
import NewCardForm from "components/account/AccountPayment/AddNewCard/components/NewCardForm";
import {DockModal, Loader} from "components/common";
import {A11yButton} from "components/common/buttons/A11yButton";
import {useThemeValues} from "hooks/useThemeValues";
import {useAppSelector} from "state/redux/hooks";
import {orderSelectors} from "state/redux/slices/order";
import {DEFAULT_THEME} from "constants/themes/defaultTheme";
import {GLOBAL_STYLES} from "styles/globalStyles";
import {IconSmallArrowLeft} from "assets/images";

const AddPaymentMethod = (props) => {
  const {isDockModal, goBack, isOpen, onSave, loading} = props;

  const {translucentPrimaryColor} = useThemeValues();
  const theme = useAppSelector(orderSelectors.getTheme) || DEFAULT_THEME;

  const renderHeader = () => {
    return (
      <Flex {...styles.headerRowContainer}>
        <Flex {...styles.headerColumnContainer}>
          <A11yButton onAction={goBack}>
            <Image {...styles.svgImage} src={IconSmallArrowLeft} />
          </A11yButton>
          <Text {...styles.headerRowText}>Payment</Text>
        </Flex>
      </Flex>
    );
  };

  return isDockModal ? (
    <DockModal
      header="Add Credit Card"
      headerTextColor="NEW_TEXT_PRIMARY_BLUE"
      isOpen={isOpen}
      toggle={goBack}
      loading={loading}
      size={1}
      provideBackOption
    >
      <Flex {...styles.screenContainer} height="calc( 100% - 67px )">
        <Flex
          {...styles.bodyContainer}
          style={{
            // @ts-expect-error
            "--theme-color": theme?.primaryColor,
            "--theme-secondary-color": translucentPrimaryColor,
            "--theme-border-radius": theme?.borderRadius,
            // @ts-check
          }}
        >
          <NewCardForm onSave={onSave} />
        </Flex>
      </Flex>
    </DockModal>
  ) : (
    <Flex {...styles.screenContainer}>
      <Flex
        {...styles.bodyContainer}
        style={{
          // @ts-expect-error
          "--theme-color": theme?.primaryColor,
          "--theme-secondary-color": translucentPrimaryColor,
          "--theme-border-radius": theme?.borderRadius,
          // @ts-check
        }}
      >
        {loading && <Loader />}
        {renderHeader()}
        <NewCardForm onSave={onSave} />
      </Flex>
    </Flex>
  );
};

const styles = {
  screenContainer: {
    sx: {
      height: "calc(var(--app-height))",
      justifyContent: "space-between",
      flexDirection: "column",
      maxWidth: GLOBAL_STYLES.MAX_WIDTH_CONTAINER,
      margin: "0 auto",
    },
  },
  bodyContainer: {
    flex: 1,
    padding: "0 15px 20px",
    sx: {
      justifyContent: "space-between",
      flexDirection: "column",
    },
  },
  headerRowContainer: {
    sx: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      height: "67px",
    },
  },
  headerRowText: {
    sx: {
      fontSize: 18,
      fontWeight: 600,
      color: "NEW_TEXT_PRIMARY_BLUE",
    },
    margin: "0 auto",
  },
  headerColumnContainer: {
    sx: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
  },
};

export default AddPaymentMethod;
