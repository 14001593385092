import React, {useEffect, useState} from "react";
import {Box} from "@material-ui/core";
import {toast} from "react-toastify";
import {Flex} from "rebass/styled-components";
import DockModal from "components/common/DockModal";
import {useAppDispatch} from "state/redux/hooks";
import {businessActions} from "state/redux/slices/business/slice";
import {fetchBusinessSettings} from "api/business";
import {updateCustomerNotes} from "api/customer";
import {
  createCustomerOptionSelection,
  deleteCustomerOptionSelection,
  fetchPreferences,
  updateCustomerOptionSelection,
} from "api/online-order";
import PreferencesForm from "./Form";

const styles = {
  wrapper: {
    height: "calc(100% - 67px)",
    flexDirection: "column",
    justifyContent: "space-between",
    py: "18px",
    overflow: "auto",
  },
};

const GENERIC_ERROR_MSG = "something went wrong, please try again later";

/** @deprecated Use src/components/common/sectionItems/AutonomousCarePreferences/AutonomousCarePreferences.tsx instead */
const PreferenceDockForm = ({
  header,
  isOpen,
  toggle,
  businessId,
  storeId,
  customer,
  saveSelections,
}) => {
  const dispatch = useAppDispatch();
  const [preferences, setPreferences] = useState({});

  const applySingleSelection = async (newSelectedOption, preference) => {
    // we check if an option has already been selected for the given pref first
    const currentSelected = preference.options.find((option) => option.selected);
    if (currentSelected && typeof currentSelected.selectionId !== "undefined") {
      // if yes, we replace the current selection by the new one
      await updateCustomerOptionSelection(currentSelected.selectionId, {
        newOptionId: newSelectedOption.id,
      });
    } else {
      // if not, we create a new selection
      await createCustomerOptionSelection({preferenceOptionId: newSelectedOption.id});
    }
  };

  const applyMultiSelection = async (selectedOption) => {
    // we check if the selection already in selected state (users toggle off selection)
    if (selectedOption.selected && typeof selectedOption.selectionId !== "undefined") {
      // in that case, users want to unselect the option, we delete the selection
      await deleteCustomerOptionSelection(selectedOption.selectionId);
    } else {
      // otherwise it's a new selection
      await createCustomerOptionSelection({preferenceOptionId: selectedOption.id});
    }
  };

  const handleSelectionChange = async (selection, preference) => {
    try {
      if (preference.type === "single") {
        await applySingleSelection(selection, preference);
      } else {
        await applyMultiSelection(selection, preference);
      }

      const updatedPreferences = await fetchPreferences(businessId);
      if (updatedPreferences.data.success && updatedPreferences.data.preferences) {
        setPreferences({
          ...preferences,
          businessCustomerPreferences: updatedPreferences.data.preferences,
        });
      }
    } catch (e) {
      toast.error(GENERIC_ERROR_MSG);
    }
  };

  useEffect(() => {
    async function initPreferences() {
      try {
        const [customerPrefsResp, businessSettingsResp] = await Promise.all([
          fetchPreferences(businessId),
          fetchBusinessSettings(businessId),
        ]);

        if (customerPrefsResp.data.success && businessSettingsResp.data.success) {
          let prefs = {
            enabled:
              businessSettingsResp.data.businessSettings?.isCustomPreferencesEnabled,
            businessCustomerPreferences: customerPrefsResp.data.preferences,
            hangDry: {
              enabled: businessSettingsResp.data.businessSettings?.isHangDryEnabled,
              instructions:
                businessSettingsResp.data.businessSettings?.hangDryInstructions,
            },
          };
          setPreferences(prefs);
          dispatch(
            businessActions.setBusinessSettingsAction(
              businessSettingsResp.data?.businessSettings
            )
          );
        } else {
          toast.error(GENERIC_ERROR_MSG);
        }
      } catch (e) {
        toast.error("something went wrong, please try again later");
      }
    }

    initPreferences();
  }, [businessId]);

  const handleSave = async ({
    customerNotes,
    isHangDrySelected,
    hangDryCustomerInstructions,
  }) => {
    const params = {
      notes: customerNotes,
      isHangDrySelected: isHangDrySelected,
      hangDryInstructions: hangDryCustomerInstructions,
    };

    await updateCustomerNotes(storeId, customer?.id, params); // customer.id - centsCustomerId
    saveSelections(params, customerNotes);

    toggle();
  };

  return (
    <DockModal header={header} isOpen={isOpen} toggle={toggle}>
      <Flex {...styles.wrapper}>
        <Box>
          <PreferencesForm
            preferences={preferences}
            onChangeSelection={handleSelectionChange}
            onSave={handleSave}
            regularPreferences={{
              isHangDrySelected: customer?.isHangDrySelected,
              hangDryInstructions: customer?.hangDryInstructions,
              notes: customer?.notes,
            }}
          />
        </Box>
      </Flex>
    </DockModal>
  );
};

export default PreferenceDockForm;
