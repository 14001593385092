import {FC, ReactNode, useEffect} from "react";
import {useLDClient} from "launchdarkly-react-client-sdk";
import {useLogout} from "hooks/auth/useLogout";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {businessSelectors} from "state/redux/slices/business";
import {customerActions} from "state/redux/slices/customer";
import {getCentsCustomer} from "state/redux/slices/customer/selectors/credentialsSelector";
import {fetchCentsCustomer} from "api/customer/fetchCentsCustomer";
import {getCustomerAuthToken} from "api/httpClient";

export const CentsCustomerProvider: FC<{
  children: ReactNode;
}> = ({children}) => {
  const ldClient = useLDClient();
  const dispatch = useAppDispatch();
  const {logout} = useLogout();
  const businessId = useAppSelector(businessSelectors.getBusinessId);
  const centsCustomer = useAppSelector(getCentsCustomer);
  const centsCustomerId = centsCustomer?.id;

  const isCustomerAvailableToFetch = !centsCustomer && getCustomerAuthToken();

  useEffect(
    function getCentsCustomerData() {
      if (isCustomerAvailableToFetch) {
        fetchCentsCustomer()
          .then((response) => {
            dispatch(customerActions.setCentsCustomer(response.data));
          })
          .catch(logout);
      }
    },
    [dispatch, isCustomerAvailableToFetch, logout]
  );

  useEffect(function applyCentsCustomerFeatureFlags() {
    if (centsCustomerId && businessId) {
      const user = {
        kind: "user",
        key: `${businessId}`,
        centsCustomerId,
        businessId: Number(businessId),
        custom: {
          businessId: Number(businessId),
        },
      };

      ldClient?.identify(user);
    }
  });

  return <>{children}</>;
};
