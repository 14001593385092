import {useState} from "react";
import {ServiceCheckboxProps} from "components/common/checkboxes/ServiceCheckbox/ServiceCheckbox";
import {useOrderConfig} from "hooks/newOrder/useOrderConfig";
import {useIsCategorySelected} from "hooks/orderBuilder/useIsCategorySelected";
import {useUpdateBagsCount} from "hooks/orderBuilder/useUpdateBagsCount";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {
  getAvailableLaundryServices,
  getBagsCount,
  getCustomerSelectedServices,
  getIsModifiersDrawerOpened,
  getServiceMultiSelectEnabled,
} from "state/redux/slices/order/selectors/servicesSelector";
import {getIsFetchingStore} from "state/redux/slices/order/selectors/storeSelectors";
import {ServiceCategoryTypes} from "state/redux/slices/order/types";
import {
  ILaundryService,
  ISelectedService,
} from "state/redux/slices/order/types/orderBuilder";
import {formatSelectedServices} from "utils/formatters/formatSelectedServices";
import {DryCleaningForm, LaundryForm} from "types/orderBuilder/services/serviceSelector";

export interface ServiceDrawer extends LaundryForm, DryCleaningForm {
  isSubmitButtonDisabled: boolean;
  onServiceDrawerSubmit: () => void;
  onServiceDrawerOpen: () => void;
}

export const useServicesDrawer = (): ServiceDrawer => {
  const dispatch = useAppDispatch();

  // Laundry
  const serviceMultiSelectEnabled = useAppSelector(getServiceMultiSelectEnabled);
  const customerSelectedServices = useAppSelector(getCustomerSelectedServices);
  const [selectedServices, setSelectedServices] = useState<ISelectedService[]>(
    customerSelectedServices
  );
  const [serviceForModifiersDrawer, setServiceForModifiersDrawer] =
    useState<ISelectedService | null>(null);
  const onServiceToggle: ServiceCheckboxProps["onClick"] = ({service, isSelected}) => {
    if (isSelected) {
      setServiceForModifiersDrawer({...service, customerSelectedModifiers: []});

      setSelectedServices(
        serviceMultiSelectEnabled
          ? [...selectedServices, {...service, customerSelectedModifiers: []}]
          : [{...service, customerSelectedModifiers: []}]
      );

      if (service.serviceModifiers.length) {
        dispatch(orderActions.toggleModifiersDrawer());
      }
    } else {
      const updatedServiceSelection = selectedServices.filter(
        ({id: selectedServiceId}) => selectedServiceId !== service.id
      );
      setSelectedServices(updatedServiceSelection);
    }
  };
  const onModifiersSave = (modifiersToUpdate: ILaundryService["serviceModifiers"]) => {
    const updatedServices = selectedServices.map((chosenService) => {
      const isCurrentService = chosenService.id === serviceForModifiersDrawer?.id;

      return isCurrentService
        ? {...chosenService, customerSelectedModifiers: modifiersToUpdate}
        : chosenService;
    });

    setSelectedServices(updatedServices);
    dispatch(orderActions.toggleModifiersDrawer());
  };
  const isFetchingStore = useAppSelector(getIsFetchingStore);
  const currentBagsCount = useAppSelector(getBagsCount);
  const availableServices = useAppSelector(getAvailableLaundryServices);
  const isModifiersDrawerOpened = useAppSelector(getIsModifiersDrawerOpened);
  const {isDoorDashOnlyStore} = useOrderConfig();
  const onModifiersDrawerClose = () => {
    dispatch(orderActions.toggleModifiersDrawer());
  };

  // Dry Cleaning
  const orderDryCleaningCategory = useIsCategorySelected({
    category: ServiceCategoryTypes.DRY_CLEANING,
  });
  const [isDryCleaningCategorySelected, setIsDryCleaningCategorySelected] = useState(
    orderDryCleaningCategory
  );
  const onDryCleaningCategoryToggle = () => {
    setIsDryCleaningCategorySelected((prev) => !prev);
  };

  // Bags
  const {onMinusBagCount, onPlusBagCount} = useUpdateBagsCount();

  // Form
  const onServiceDrawerOpen = () => {
    setSelectedServices(customerSelectedServices);
    setIsDryCleaningCategorySelected(orderDryCleaningCategory);
    dispatch(orderActions.setIsServicesDrawerOpened(true));
  };
  const onServiceDrawerSubmit = () => {
    dispatch(
      orderActions.setIsLaundryCategorySelected({
        newValue: customerSelectedServices.length > 0,
        shouldResetServiceData: false,
      })
    );
    dispatch(orderActions.setCustomerSelectedServices(selectedServices));
    dispatch(
      orderActions.setIsDryCleaningCategorySelected(isDryCleaningCategorySelected)
    );
    dispatch(orderActions.setIsServicesDrawerOpened(false));
  };
  const isSubmitButtonDisabled =
    !isDryCleaningCategorySelected && selectedServices.length === 0;

  return {
    selectedServices: formatSelectedServices(selectedServices),
    serviceForModifiersDrawer,
    isFetchingStore,
    isDoorDashOnlyStore,
    currentBagsCount,
    availableServices,
    isModifiersDrawerOpened,
    isDryCleaningCategorySelected,
    isSubmitButtonDisabled,
    onDryCleaningCategoryToggle,
    onServiceToggle,
    onServiceDrawerSubmit,
    onModifiersDrawerClose,
    onMinusBagCount,
    onPlusBagCount,
    onModifiersSave,
    onServiceDrawerOpen,
  };
};
