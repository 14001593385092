import {useState} from "react";
import {Button} from "@mui/material";
import PropTypes from "prop-types";
import {Drawer} from "components/common/drawers/Drawer";
import OrderNotesForm from "components/newOrder/common/OrderNotesForm";
import {EN_LOCALE} from "locales/en";
import "./styles.scss";

const OrderNotesDockModal = ({
  onOrderNotesChange,
  readonly,
  orderNotes,
  isOpen,
  toggle,
}) => {
  const [notes, setNotes] = useState(orderNotes);

  const onSaveOrderNotes = () => {
    onOrderNotesChange(notes);
  };
  return (
    <Drawer isShowingDrawer={isOpen} closeDrawer={toggle} title="Order Notes">
      <div className="notes-wrapper">
        <div className="notes-form">
          <OrderNotesForm
            onOrderNotesChange={(e) => setNotes(e.target.value)}
            orderNotes={notes}
            readonly={readonly}
          />
        </div>

        {readonly ? null : (
          <div className="new-order-sticky-button">
            <div className="footer-button-wrapper">
              <Button
                color="primary"
                variant="contained"
                className="footer-button"
                onClick={onSaveOrderNotes}
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "28px",
                  padding: "19px",
                  textTransform: "capitalize",
                }}
                fullWidth
              >
                {EN_LOCALE.button.save}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

OrderNotesDockModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onOrderNotesChange: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  orderNotes: PropTypes.string.isRequired,
};

OrderNotesDockModal.defaultProps = {
  readonly: false,
};

export default OrderNotesDockModal;
