import {modifiersPricingTypeLabels} from "../../constants/order";
export {formatAddress, createAddressPayload} from "../../utils/address";

export const toDollars = (amount) => {
  return `$${amount?.toFixed(2) || "0.00"}`;
};

export const getServicePriceBreakdown = (service) => {
  const {prices, hasMinPrice} = service;
  const priceString = getPriceString(service);

  let minPriceString;
  let isShortMinPriceDescription = false;

  if (hasMinPrice) {
    const {storePrice, minQty, minPrice} = prices[0];

    isShortMinPriceDescription =
      (Number(minQty || 0) * Number(storePrice || 0)).toFixed(2) ===
      Number(minPrice || 0).toFixed(2);
    minPriceString = isShortMinPriceDescription
      ? isShortMinPriceDescription && minQty
        ? `(min. ${minQty}lbs)`
        : ""
      : `Min: First ${minQty}lbs @ ${toDollars(minPrice)}`;
  }

  return {priceString, minPriceString, isShortMinPriceDescription};
};

export const getPriceString = ({serviceCategory, prices}) => {
  const {storePrice} = prices[0];
  return `${toDollars(storePrice)} / ${
    serviceCategory.category === "PER_POUND" ? "lb" : "unit"
  }`;
};

export const getMinPriceString = ({prices}) => {
  const {minPrice} = prices[0];
  return toDollars(minPrice);
};

export const getModifierPriceString = ({price, pricingType}) => {
  const unitLabel = modifiersPricingTypeLabels[pricingType];

  return `${price?.toFixed(2)} / ${unitLabel}`;
};
