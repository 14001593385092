import {FC} from "react";
import {Skeleton} from "@mui/material";
import cx from "classnames";
import {BagsCounter} from "components/common/orderBuilder/BagsCounter";
import {CategorySelectionCard} from "components/common/orderBuilder/CategorySelectionCard";
import {ModifiersDrawer} from "components/orderCreation/serviceFirstFlow/home/components/ServicesSection/components/LaundryCategory/components/ModifiersDrawer";
import {useFullStoryEvent} from "hooks/fullstory/useFullStoryEvent";
import {useOrderConfig} from "hooks/newOrder/useOrderConfig";
import {useIsCategorySelected} from "hooks/orderBuilder/useIsCategorySelected";
import {useUpdateBagsCount} from "hooks/orderBuilder/useUpdateBagsCount";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {
  getAvailableLaundryServices,
  getBagsCount,
  getIsDryCleaningAvailableForDelivery,
  getSelectedService,
} from "state/redux/slices/order/selectors/servicesSelector";
import {getIsFetchingStore} from "state/redux/slices/order/selectors/storeSelectors";
import {ServiceCategoryTypes} from "state/redux/slices/order/types";
import {getLaundryCategoryHeight} from "utils/order/getLaundryCategoryHeight";
import {
  LAUNDRY_CATEGORY_SELECTED,
  LAUNDRY_CATEGORY_UNSELECTED,
  LAUNDRY_SERVICE_AUTO_SELECT,
} from "constants/fullStory/events/service";
import {MAXIMUM_BAGS_COUNT} from "constants/order";
import {LaundryServiceIllustration} from "assets/images";
import {LaundryServiceButton} from "./components/LaundryServiceButton";
import styles from "./laundryCategory.module.scss";

/**
 * @deprecated Details: https://cents.atlassian.net/browse/DEL-725
 *
 * Use src/components/common/forms/services/ServiceSelectionForm/ServiceSelectionForm.tsx instead
 */
export const LaundryCategory: FC = () => {
  const dispatch = useAppDispatch();
  const isCategorySelected = useIsCategorySelected({
    category: ServiceCategoryTypes.LAUNDRY,
  });
  const {submitEvent} = useFullStoryEvent();
  const currentBagsCount = useAppSelector(getBagsCount);
  const isFetchingStore = useAppSelector(getIsFetchingStore);
  const isDryCleaningAvailableForDelivery = useAppSelector(
    getIsDryCleaningAvailableForDelivery
  );
  const selectedLaundryService = useAppSelector(getSelectedService);
  const availableServices = useAppSelector(getAvailableLaundryServices);

  const {onMinusBagCount, onPlusBagCount} = useUpdateBagsCount();
  const {maxLaundryTurnAround, isDoorDashOnlyStore} = useOrderConfig();

  const toggleCategory = () => {
    if (isDryCleaningAvailableForDelivery || availableServices.length > 1) {
      submitEvent(
        isCategorySelected ? LAUNDRY_CATEGORY_UNSELECTED : LAUNDRY_CATEGORY_SELECTED
      );

      dispatch(
        orderActions.setIsLaundryCategorySelected({newValue: !isCategorySelected})
      );

      if (availableServices.length === 1) {
        submitEvent(LAUNDRY_SERVICE_AUTO_SELECT);
        dispatch(orderActions.setSelectedLaundryService(availableServices[0]));
      }
    }
  };

  const activeCategoryHeight = getLaundryCategoryHeight(availableServices.length);

  return (
    <div
      className={cx(styles.categoryContainer, {
        [styles.active]: isCategorySelected,
      })}
    >
      {isFetchingStore ? (
        <Skeleton variant="rectangular" className={styles.skeleton} />
      ) : (
        <CategorySelectionCard
          imageSource={LaundryServiceIllustration}
          title="Laundry"
          subtitle={`${maxLaundryTurnAround}hr Turnaround`}
          itemSelected={isCategorySelected}
          onClick={toggleCategory}
          customStyle={isCategorySelected ? {height: activeCategoryHeight} : {}}
        >
          <div className={styles.detailsSection}>
            <BagsCounter
              value={currentBagsCount}
              onMinus={onMinusBagCount}
              onPlus={onPlusBagCount}
              min={1}
              max={
                isDoorDashOnlyStore
                  ? MAXIMUM_BAGS_COUNT.DOORDASH_ONLY
                  : MAXIMUM_BAGS_COUNT.GLOBAL
              }
            />
            <h4 className={styles.title}>Laundry services</h4>
            <div className={styles.servicesWrapper}>
              {availableServices.map((service) => (
                <LaundryServiceButton
                  key={service.id}
                  service={service}
                  isActive={selectedLaundryService?.id === service.id}
                />
              ))}
            </div>
          </div>
        </CategorySelectionCard>
      )}
      <ModifiersDrawer />
    </div>
  );
};
