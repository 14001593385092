import {AxiosPromise} from "axios";
import {MachineTypes, SelfServiceStatuses, ServiceTypes} from "constants/index";
import httpClient from "./httpClient";

export interface FetchRealtimeDeviceStatusResponse {
  activeTurn: {
    id?: number;
    serviceType?: ServiceTypes;
    centsCustomerId?: number | null;
    status?: SelfServiceStatuses;
  } | null;
  isDumbDumb: boolean;
  lastStartedDate: string | null;
  timeZone: string | null;
  machineName: string;
  machineType: MachineTypes;
  success: boolean;
}

export const fetchRealtimeDeviceStatus: (
  machineId: number
) => AxiosPromise<FetchRealtimeDeviceStatusResponse> = (machineId: number) => {
  return httpClient({
    method: "POST",
    url: `/live-status/machine/${machineId}/realtime-status`,
  });
};
