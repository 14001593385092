import {AxiosResponse} from "axios";
import httpClient from "api/httpClient";

export interface CentsCustomer {
  /** ISO */
  createdAt: string;
  customerShortcode: string;
  /** ISO */
  deletedAt: string | null;
  /** ISO */
  deletionInitiatedAt: string | null;
  email: string;
  firstName: string;
  id: number;
  isDeleted: boolean;
  isGuest: boolean;
  languageId: number;
  lastName: string;
  optOutMarketingSms: boolean;
  optOutUpdatesSms: boolean;
  phoneNumber: string;
  stripeCustomerId: string;
  /** ISO */
  updatedAt: string;
}

export const fetchCentsCustomer = (): Promise<AxiosResponse<CentsCustomer>> => {
  return httpClient({
    method: "GET",
    url: "/live-status/customer",
  });
};
