import cx from "classnames";
import PropTypes from "prop-types";
import {Button, Flex, Text} from "rebass/styled-components";
import {Loader} from "components/common";
import {OptOutInfoBanner, OptOutInfoBannerType} from "components/common/OptOutInfoBanner";
import TipSelection from "components/common/TipSelection";
import {HEADER_LEFT_ICON_TYPES} from "constants/order";
import {GLOBAL_STYLES} from "styles/globalStyles";
import {BackButton} from "../BackButton";
import moduleStyles from "./orderScreenWrapper.module.scss";

export const OrderScreenWrapper = ({
  children,
  header,
  disableBtn,
  submitText,
  onSubmit,
  loading,
  error,
  showPoweredByCents,
  tipSettings,
}) => {
  return (
    <>
      {loading && <Loader style={{position: "fixed", backgroundColor: "#fff"}} />}
      <Flex {...styles.main.container}>
        <Flex {...styles.main.wrapper}>
          <Flex {...styles.header.wrapper}>
            <BackButton forceBack={true} sx={{...styles.header.image.sx}} />
            <Text {...styles.header.text}>{header}</Text>
          </Flex>
          <OptOutInfoBanner type={OptOutInfoBannerType.stretch} />
          <div className={cx(moduleStyles.content, "content")}>{children}</div>
          {error ? <Text variant="errorMessage">{error}</Text> : null}
          <div className="new-order-sticky-button">
            {tipSettings?.isOnlineTippingAllowed && <TipSelection {...tipSettings} />}
            <Flex {...styles.footer.wrapper}>
              <Button
                variant="primary"
                {...styles.footer.button}
                disabled={disableBtn}
                onClick={onSubmit}
                className="outline-distant"
              >
                {submitText}
              </Button>
            </Flex>
            {showPoweredByCents ? (
              <Text {...styles.poweredByCents}>Powered by Cents</Text>
            ) : null}
          </div>
        </Flex>
      </Flex>
    </>
  );
};

OrderScreenWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  businessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  header: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disableBtn: PropTypes.bool,
  submitText: PropTypes.string,
  error: PropTypes.string,
  showPoweredByCents: PropTypes.bool,
  tipSettings: PropTypes.object,
  onBack: PropTypes.func,
  headerLeftIconType: PropTypes.string,
};

OrderScreenWrapper.defaultProps = {
  loading: false,
  disableBtn: false,
  submitText: "Next",
  showPoweredByCents: false,
  tipSettings: {},
  onBack: () => history.back(),
  headerLeftIconType: HEADER_LEFT_ICON_TYPES.CLOSE,
};

const styles = {
  main: {
    container: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    wrapper: {
      height: "100%",
      width: "100%",
      maxWidth: GLOBAL_STYLES.MAX_WIDTH_CONTAINER,
      flexDirection: "column",
    },
  },
  header: {
    wrapper: {
      height: "67px",
      minHeight: "67px",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "18px",
      bg: "WHITE",
      sx: {
        zIndex: 49,
        position: "sticky",
        top: 0,
      },
    },
    text: {
      fontWeight: 600,
      fontSize: "18px",
      color: "NEW_TEXT_PRIMARY_BLUE",
      lineHeight: "22px",
    },
    image: {
      sx: {
        position: "absolute",
        top: "20px",
        left: "16px",
      },
    },
  },
  footer: {
    wrapper: {
      p: "16px",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.48px",
      padding: "17px",
      width: "100%",
      color: "WHITE",
    },
  },
  confirmation: {
    wrapper: {
      textAlign: "center",
      m: "0 24px",
    },
    mainText: {
      fontSize: "16px",
      fontWeight: 600,
      color: "NEW_TEXT_PRIMARY_BLUE",
    },
    subtext: {
      mt: "4px",
      fontWeight: 400,
      fontSize: "14px",
      color: "NEW_TEXT_GREY",
    },
  },
  poweredByCents: {
    textAlign: "center",
    color: "TEXT_GREY",
    lineHeight: "14px",
    fontSize: "12px",
    pb: "18.5px",
    fontWeight: 700,
  },
};
