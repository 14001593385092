import {FC, ReactNode} from "react";
import {BackButton} from "components/newOrder/common/BackButton";
import {useThemeValues} from "hooks/useThemeValues";
import {useAppSelector} from "state/redux/hooks";
import {orderSelectors} from "state/redux/slices/order";
import {DEFAULT_THEME} from "constants/themes/defaultTheme";
import "./StaticPageLayout.style.scss";

interface StaticPageLayoutProps {
  children: ReactNode;
  headerText: string;
  bgLayout?: string;
  forceBack?: boolean;
}

export const StaticPageLayout: FC<StaticPageLayoutProps> = ({
  children,
  headerText,
  bgLayout,
  forceBack,
}) => {
  const {translucentPrimaryColor} = useThemeValues();
  const theme = useAppSelector(orderSelectors.getTheme) || DEFAULT_THEME;

  return (
    <main
      className={`static-screen ${bgLayout || ""}`}
      style={{
        // @ts-ignore
        "--theme-color": theme?.primaryColor,
        "--theme-secondary-color": translucentPrimaryColor,
        "--theme-border-radius": theme?.borderRadius,
        // @ts-check
      }}
    >
      <article>
        <header>
          <BackButton forceBack={forceBack} />
          <h2>{headerText}</h2>
        </header>
        <section className="content">{children}</section>
      </article>
    </main>
  );
};
