import {FC, useEffect, useRef, useState} from "react";
import {useAppSelector} from "state/redux/hooks";
import {businessSelectors} from "state/redux/slices/business";
import {IBusinessThemeData} from "state/redux/slices/business/types";
import {orderSelectors} from "state/redux/slices/order";
import {ITheme} from "types/theme";
import "./styles.scss";

export const DEFAULT_CENTS_LOGO = "Cents+LOGO.png";

const enum ViewType {
  DEFAULT = "default",
  CUSTOM = "custom",
}

interface IExtendedTheme extends ITheme {
  name?: string;
  storeId?: string | number;
}

interface ICustomLogo {
  initialTheme?: Partial<IExtendedTheme>;
}

const CustomLogo: FC<ICustomLogo> = ({initialTheme}) => {
  const stateTheme = useAppSelector(orderSelectors.getTheme);
  const businessTheme = useAppSelector(businessSelectors.getBusinessTheme);
  const theme: Partial<IExtendedTheme> | IBusinessThemeData | undefined =
    initialTheme || stateTheme || businessTheme || undefined;
  const {logoUrl, businessName, name} = theme || {};
  const logoTitle: string = name || businessName || "";
  const [viewType, setViewType] = useState<ViewType>(ViewType.DEFAULT);
  const [loaded, setLoaded] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const delay = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    setLoaded(false);

    if (logoUrl && !logoUrl?.includes(DEFAULT_CENTS_LOGO)) {
      setViewType(ViewType.CUSTOM);
    } else {
      setViewType(ViewType.DEFAULT);
    }
    delay(50).then(() => setLoaded(true));
  }, [logoUrl]);

  return (
    <div className="custom-logo-wrapper" ref={wrapperRef} data-loaded={loaded}>
      {viewType === ViewType.DEFAULT && logoTitle && (
        <span className="custom-logo">{logoTitle}</span>
      )}
      {viewType === ViewType.CUSTOM && logoUrl && (
        <img className="custom-logo" src={logoUrl} alt={logoTitle} />
      )}
    </div>
  );
};

export default CustomLogo;
