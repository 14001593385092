export const ONLINE_ORDER_REDUCER = "ONLINE_ORDER";
export const GET_ORDER_INITIAL_DATA = "GET_ORDER_INITIAL_DATA";
export const DELETE_CUSTOMER_ADDRESS = "DELETE_CUSTOMER_ADDRESS";
export const GET_TURNAROUND_TIME_FOR_CATEGORIES = "GET_TURNAROUND_TIME_FOR_CATEGORIES";
export const GET_CUSTOMER_SUBSCRIPTIONS_LIST = "GET_CUSTOMER_SUBSCRIPTIONS_LIST";
export const GET_SERVICE_TYPE_AVAILABILITY = "GET_SERVICE_TYPE_AVAILABILITY";
export const GET_RETURN_WINDOWS = "GET_RETURN_WINDOWS";
export const GET_NEAR_STORES = "GET_NEAR_STORES";
export const PATCH_CUSTOMER_CREDENTIALS = "PATCH_CUSTOMER_CREDENTIALS";
export const FETCH_SERVICES_PRICES = "FETCH_SERVICES_PRICES";
export const FETCH_SERVICE_AND_MODIFIERS = "FETCH_SERVICE_AND_MODIFIERS";
export const FETCH_DELIVERY_SETTING = "FETCH_DELIVERY_SETTING";
export const FETCH_ONLINE_ORDER_SERVICES = "FETCH_ONLINE_ORDER_SERVICES";
