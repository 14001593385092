import {FC, ReactNode} from "react";
import cn from "classnames";
import {Button, ButtonVariant} from "components/common/buttons/Button";
import styles from "./sectionActionButton.module.scss";

export const SectionActionButton: FC<{
  onClick: () => void | Promise<void>;
  children: ReactNode;
}> = ({onClick, children}) => {
  return (
    <Button
      onClick={onClick}
      variant={ButtonVariant.UNDERLINED}
      className={cn(styles.actionButton, styles.rightButton)}
    >
      {children}
    </Button>
  );
};
