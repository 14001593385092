import qs from "query-string";
import {ACCESS_TOKEN_KEY} from "constants/auth";
import {EMAIL_REGEXP} from "constants/constants";
import {CENTS_IN_DOLLAR} from "constants/order";
import customerState, {initialCustomerState} from "../state/customer";
import {
  BUSINESS_ASSETS_KEY,
  BUSINESS_OWNER_AUTH_TOKEN_KEY,
  CUSTOMER_AUTH_TOKEN_KEY,
  CUSTOMER_KEY,
  TEAM_MEMBER,
} from "./config";

export const getQueryString = (search) => {
  return qs.parse(search?.split("?")[1]);
};

export const convertToQueryString = qs.stringify;

export const logoutCustomer = () => {
  localStorage.removeItem(CUSTOMER_AUTH_TOKEN_KEY);
  localStorage.removeItem(CUSTOMER_KEY);
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(TEAM_MEMBER);
  sessionStorage.removeItem(TEAM_MEMBER);
  sessionStorage.removeItem(BUSINESS_OWNER_AUTH_TOKEN_KEY);
  sessionStorage.removeItem(BUSINESS_ASSETS_KEY);
  customerState.set(initialCustomerState);
};

export const getDeliveryAcknowledgementKey = (orderId) => {
  return `liveLink:${orderId}-scheduledDeliveryAcknowledged`;
};

export const getDeliveryAfterProcessingAcknowledgementKey = (orderId) => {
  return `liveLink:${orderId}-scheduledDeliveryAfterProcessingAcknowledged`;
};

export const getSkipRecurringPickupAcknowledgementKey = (orderId) => {
  return `liveLink:${orderId}-skipRecurringPickupAcknowledged`;
};

export const setStringifiedLocalStorageData = (itemName, item) => {
  try {
    localStorage.setItem(itemName, JSON.stringify(item));
  } catch (error) {
    console.log(error);
  }
};

export const getParsedLocalStorageData = (itemName) => {
  try {
    return JSON.parse(localStorage.getItem(itemName)) || {};
  } catch (error) {
    return {};
  }
};

export const isValidEmail = (value) => EMAIL_REGEXP.test(value);

export const isDigit = (value) => {
  return new RegExp(/[0-9]/).test(value);
};

export const applyMaskTemplate = (value, template) => {
  let iterator = 0;
  return template
    .split("")
    .map((item) => {
      if (isDigit(item)) {
        const returnValue = value.at(iterator);
        iterator++;
        return returnValue;
      }
      return item;
    })
    .join("");
};

/**
 * This utility method will convert amount of dollars to cents
 * and returns amount of cents
 *
 * @param {number} amountInDollars
 * @return {number}
 */
export const convertDollarsToCents = (amountInDollars) => {
  const amountParsed = Number(amountInDollars);
  if (Number.isNaN(amountParsed)) {
    throw new Error("Not a number passed to function");
  }
  return Math.round(amountParsed * CENTS_IN_DOLLAR);
};

/**
 * Filters the properties in the given object based on the provided properties array.
 *
 * @param {Object} object - The input object
 * @param {Array} properties - The properties to filter by
 * @return {Object} The filtered object
 */
export const filterPropertiesInObject = (object, properties) => {
  return Object.keys(object)
    .filter((key) => properties.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
};
