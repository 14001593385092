import {Typography} from "@material-ui/core";
import {ScheduleWindow} from "components/newOrder/routes/schedule/hooks/useScheduling/types";
import {getNearStoresData} from "state/redux/slices/order/selectors";
import {SCHEDULE} from "constants/order";
import {useThemeValues} from "hooks/useThemeValues";
import {DateTime} from "luxon";
import {FC} from "react";
import {useSelector} from "react-redux";

interface IAutoScheduleNotification {
  currentWindow: ScheduleWindow | null;
}

export const AutoScheduleNotification: FC<IAutoScheduleNotification> = ({
  currentWindow,
}) => {
  const {translucentPrimaryColor} = useThemeValues();
  const {data: availableStores} = useSelector(getNearStoresData);

  if (!currentWindow) {
    return null;
  }

  const startTime = currentWindow.autoScheduleTiming?.startTime || "";
  const endTime = currentWindow.autoScheduleTiming?.endTime || "";
  const timeZone = availableStores?.deliveryDays[0]?.timeZone;
  const returnMomentDate = DateTime.fromISO(startTime).setZone(timeZone);

  return (
    <div
      className="auto-schedule-notification-container"
      style={{backgroundColor: translucentPrimaryColor}}
    >
      <Typography className="autoschedule-title">
        {SCHEDULE.returnDeliveryAuto}
      </Typography>
      {`${returnMomentDate.toFormat("ccc")}, ${returnMomentDate.toFormat("LLL. dd,")}
      ${returnMomentDate.toFormat("h:mma").toLowerCase()}`}
      {" - "}
      {`${DateTime.fromISO(endTime).setZone(timeZone).toFormat("h:mma").toLowerCase()}`}
    </div>
  );
};
