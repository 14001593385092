import {useLayoutEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {toast} from "react-toastify";
import {IPreferenceItem} from "components/account/AccountCarePreferences/assets/AccountCarePreferencesForm/types";
import {ToastError, errorToastOptions} from "components/newOrder/common/Toast";
import {useAppSelector} from "state/redux/hooks";
import {getStoreId} from "state/redux/slices/order/selectors";
import {fetchCustomerInfo, fetchPreferences} from "api/online-order";

const ERROR_MESSAGES: Record<string, string> = {
  SELECTION_ERROR: "Something went wrong while saving information",
  FETCH_ERROR: "Something went wrong while fetching data",
  SELECT_SERVICEABLE_ADDRESS_1: "Could not load preferences.",
  SELECT_SERVICEABLE_ADDRESS_2: "You must first select an address.",
};

interface IUseAccountCarePreferences {
  businessId: string;
  setIsLoading?: (arg: boolean) => void;
  storeId?: number;
}

export interface IBusinessCustomerInfo extends Record<string, number | boolean | string> {
  availableCredits: number;
}

export interface ICustomerInfo extends Record<string, unknown> {
  id?: number; // centsCustomerId
  storePreferences?: Array<IStorePreference>;
  businessCustomer?: IBusinessCustomerInfo;
  notes?: string;
}

export interface IStorePreference {
  notes: string | null;
  isHangDrySelected: boolean;
  hangDryInstructions: string | null;
}

export const useAccountCarePreferences = ({
  businessId,
  storeId,
  setIsLoading,
}: IUseAccountCarePreferences) => {
  const {url} = useRouteMatch();
  const history = useHistory();
  const stateStoreId = useAppSelector(getStoreId);
  const [customerInfo, setCustomerInfo] = useState<ICustomerInfo>({});
  const [businessCustomerPreferences, setBusinessCustomerPreferences] = useState<
    IPreferenceItem[]
  >([]);

  const forceBack = (steps: number = -1, appendix?: string): void =>
    history.push(`${url.split("/").slice(0, steps).join("/")}${appendix || ""}`);

  const initPreferences = async (
    stateStoreId: string,
    businessId: string
  ): Promise<void> => {
    try {
      const customerPrefsResp = await fetchPreferences(businessId);
      const customerInfoResp = await fetchCustomerInfo(stateStoreId);

      if (customerPrefsResp.data.success && customerInfoResp.data.success) {
        setBusinessCustomerPreferences(customerPrefsResp.data.preferences);
        setCustomerInfo(customerInfoResp.data.customer);
        setIsLoading?.(false);
      } else {
        toast.error(
          <ToastError primaryMessage={ERROR_MESSAGES.FETCH_ERROR} />,
          errorToastOptions
        );
        forceBack();
      }
    } catch (e) {
      toast.error(
        <ToastError primaryMessage={ERROR_MESSAGES.FETCH_ERROR} />,
        errorToastOptions
      );
      forceBack();
    }
  };

  useLayoutEffect(() => {
    if ((storeId || stateStoreId) && businessId) {
      initPreferences(String(storeId || stateStoreId), businessId);
    } else {
      toast.error(
        <ToastError
          primaryMessage={ERROR_MESSAGES.SELECT_SERVICEABLE_ADDRESS_1}
          secondaryMessage={ERROR_MESSAGES.SELECT_SERVICEABLE_ADDRESS_2}
        />
      );
      forceBack(-2, "/home");
    }
  }, [stateStoreId, businessId, storeId]);

  return {
    forceBack,
    storeId: stateStoreId,
    customerInfo,
    businessCustomerPreferences,
    storePreferences: customerInfo?.storePreferences || [],
    refetchPreferences: initPreferences,
  };
};
