import React from "react";
import {Flex, Image} from "rebass/styled-components";
import {SelfServiceStatuses} from "../../constants";
import {
  LoadedClosedMachine,
  LoadedOpenMachine,
  UnloadingMachine,
} from "../../assets/images";

interface StatusImageProps {
  status?: SelfServiceStatuses;
  isDumbDumb?: boolean;
  isWaitingForTurnCreation: boolean;
}

const StatusImages = {
  [SelfServiceStatuses.CREATED]: <Image src={LoadedOpenMachine} />,
  [SelfServiceStatuses.ENABLED]: <Image src={LoadedOpenMachine} />,
  [SelfServiceStatuses.STARTED]: <Image src={LoadedClosedMachine} />,
  [SelfServiceStatuses.RUNNING]: <Image src={LoadedClosedMachine} />,
  [SelfServiceStatuses.COMPLETED]: <Image src={UnloadingMachine} />,
};

export const StatusImage = ({
  status,
  isDumbDumb,
  isWaitingForTurnCreation,
}: StatusImageProps) => {
  const turnStatusToDisplay =
    !isDumbDumb && !isWaitingForTurnCreation && status
      ? status
      : SelfServiceStatuses.CREATED;

  return <Flex {...styles.container}>{StatusImages[turnStatusToDisplay]}</Flex>;
};

const styles = {
  container: {
    justifyContent: "center",
    margin: "16px",
  },
};
