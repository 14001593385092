import {lazy} from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {ThemeProvider} from "components/common/providers/ThemeProvider";
import {Checkout} from "components/newOrder/routes/checkout";
import {Schedule, TimeSelectionScreen} from "components/newOrder/routes/schedule";
import {ServiceFirstOrderCreation} from "components/orderCreation/serviceFirstFlow";
import {Preferences} from "components/orderCreation/serviceFirstFlow/preferences";
import styles from "components/orderCreation/serviceFirstFlow/serviceFirstOrderCreation.module.scss";
import {ROUTES} from "./constants";

const Home = lazy(() => import("components/orderCreation/serviceFirstFlow/home"));

export const NewOrderRoute = () => {
  const {path} = useRouteMatch();

  return (
    <ThemeProvider>
      <div className={styles.serviceFirstOrderCreationContainer}>
        <Switch>
          <Route path={`${path}/:storeId`} exact component={ServiceFirstOrderCreation} />
          <Route path={`${path}/:storeId/${ROUTES.home}`} exact component={Home} />
          <Route
            path={`${path}/:storeId/${ROUTES.preferences}`}
            exact
            component={Preferences}
          />
          <Route
            path={`${path}/:storeId/${ROUTES.schedule}`}
            exact
            component={Schedule}
          />
          <Route
            path={`${path}/:storeId/${ROUTES.schedule}/:timeSelectionType`}
            exact
            component={TimeSelectionScreen}
          />
          <Route
            path={`${path}/:storeId/${ROUTES.checkout}`}
            exact
            component={Checkout}
          />
          <Redirect to="/" />
        </Switch>
      </div>
    </ThemeProvider>
  );
};
