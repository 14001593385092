import {useEffect, useState} from "react";
import {Drawer} from "components/common/drawers/Drawer";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {getCurrentCustomerAddress} from "state/redux/slices/order/selectors";
import {
  getDryCleaningServicesPrices,
  getLaundryServicesPrices,
  getProductsServicesPrices,
} from "state/redux/slices/order/selectors/servicesSelector";
import {getStoreId} from "state/redux/slices/order/selectors/storeSelectors";
import {fetchStoreServicesPrices} from "state/redux/slices/order/thunks/fetchStoreServicesPrices";
import {modifierPricingTypes} from "constants/order";
import {PricingButton} from "./components/PricingButton";
import {PricingEntry} from "./components/PricingEntry";
import styles from "./storeServicesPricing.module.scss";

export const StoreServicesPricing = () => {
  const dispatch = useAppDispatch();
  const storeId = useAppSelector(getStoreId);
  const laundryPrices = useAppSelector(getLaundryServicesPrices);
  const dryCleaningPrices = useAppSelector(getDryCleaningServicesPrices);
  const productsPrices = useAppSelector(getProductsServicesPrices);
  const currentCustomerAddress = useAppSelector(getCurrentCustomerAddress);

  const [isShowingDrawer, setIsShowingDrawer] = useState(false);
  const closeDrawer = () => {
    setIsShowingDrawer(false);
  };
  const openDrawer = () => {
    setIsShowingDrawer(true);
  };

  const zipCode = currentCustomerAddress?.postalCode;
  useEffect(() => {
    if (zipCode && storeId) {
      dispatch(
        fetchStoreServicesPrices({
          storeId,
          type: modifierPricingTypes.fixedPrice,
          zipCode,
        })
      );
    }
  }, [dispatch, storeId, zipCode]);

  return (
    <>
      <PricingButton openDrawer={openDrawer} />
      <Drawer
        isShowingDrawer={isShowingDrawer}
        closeDrawer={closeDrawer}
        title="Pricing"
        closeButtonClassName={styles.drawerCloseIcon}
        fullHeight={false}
      >
        <div className={styles.drawerContainer}>
          <div className={styles.drawerContent}>
            <div className={styles.description}>
              <span>Blankets and larger items are priced individually.</span>
              <br />
              <span>Please see pricing below:</span>

              {Boolean(laundryPrices?.length) && (
                <>
                  <h4 className={styles.categoryTitle}>Laundry</h4>
                  {laundryPrices?.map((service) => (
                    <PricingEntry key={service.priceId} service={service} />
                  ))}
                </>
              )}
              {Boolean(dryCleaningPrices?.length) && (
                <>
                  <h4 className={styles.categoryTitle}>Dry Cleaning</h4>
                  {dryCleaningPrices?.map((service) => (
                    <PricingEntry key={service.priceId} service={service} />
                  ))}
                </>
              )}
              {Boolean(productsPrices?.length) && (
                <>
                  <h4 className={styles.categoryTitle}>Products</h4>
                  {productsPrices?.map((service) => (
                    <PricingEntry key={service.priceId} service={service} />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};
