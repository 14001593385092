import PropTypes from "prop-types";

import {useThemeValues} from "hooks/useThemeValues";

import "./styles.scss";

import ModifierButton from "./ModifierButton";
import {getModifierPriceString} from "components/online-order/utils";

const ServiceModifiers = ({
  serviceName,
  servicePrice,
  serviceDescription,
  modifiers,
  selectedModifiersIds,
  onModifierClick,
}) => {
  const {translucentPrimaryColor} = useThemeValues();

  return (
    <div className="service-modifiers">
      <div className="service-header">
        <div className="service-header-wrapper">
          <h1 className="header-name">{serviceName}</h1>
          <h1 className="header-price">{servicePrice}</h1>
        </div>
        {serviceDescription && (
          <p className="service-description">{serviceDescription}</p>
        )}
      </div>
      <div style={{backgroundColor: translucentPrimaryColor}}>
        <h2 className="add-ons-header">Choose Add-Ons</h2>
      </div>
      <div className="modifiers-buttons">
        {modifiers
          ?.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
          .map(({name, price, pricingType, id}) => (
            <ModifierButton
              key={id}
              price={`+ $${getModifierPriceString({price, pricingType})}`}
              name={name}
              checked={selectedModifiersIds?.includes(id)}
              onClick={() => onModifierClick(id)}
            />
          ))}
      </div>
    </div>
  );
};

ServiceModifiers.propTypes = {
  serviceName: PropTypes.string,
  servicePrice: PropTypes.string,
  serviceDescription: PropTypes.string,
  modifiers: PropTypes.array,
  selectedModifiersIds: PropTypes.array,
  onModifierClick: PropTypes.func,
};

ServiceModifiers.defaultProps = {
  serviceName: "",
  servicePrice: "",
  serviceDescription: "",
  modifiers: [],
  selectedModifiersIds: [],
  onModifierClick: () => {},
};

export default ServiceModifiers;
