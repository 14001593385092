import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import {FetchingStatus} from "types/common";
import {SELF_SERVE_REDUCER} from "../constants/selfServeGeneral";
import initialData from "../constants/selfServeInitData";
import {ITheme} from "../types";
import {getBusinessThemeByUniqueCode} from "./selfServeThunks";

const selfServeSlice = createSlice({
  name: SELF_SERVE_REDUCER,
  initialState: initialData,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessThemeByUniqueCode.pending, (state) => {
        state.theme.fetchingStatus = FetchingStatus.Pending;
      })
      .addCase(
        getBusinessThemeByUniqueCode.fulfilled,
        (state, action: PayloadAction<ITheme>) => {
          state.theme.fetchingStatus = FetchingStatus.Fulfilled;
          state.theme.data = {...initialData.theme.data, ...action.payload};
        }
      )
      .addCase(getBusinessThemeByUniqueCode.rejected, (state, action) => {
        const {error} = action;
        state.theme.fetchingStatus = FetchingStatus.Rejected;
        state.theme.error = {
          text: error.message,
          code: error.name,
        };
      });
  },
});

export const selfServeReducer = selfServeSlice.reducer;
