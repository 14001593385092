import {useRef} from "react";
import {useSelector} from "react-redux";
import {
  getNearStoresData,
  getCurrentView,
  getReturnWindowsState,
} from "state/redux/slices/order/selectors";
import {checkOOBO} from "utils/ooboHelpers";
import {SCHEDULE, VIEWS} from "constants/order";
import {FETCHING_STATUS} from "constants/api";

export const useScheduleConfig = () => {
  const currentView = useSelector(getCurrentView);
  const {data: availableStores, fetchingStatus: nearStoresStatus} =
    useSelector(getNearStoresData);
  const {fetchingStatus: returnWindowsStatus} = useSelector(getReturnWindowsState);

  const isOOBOManager = checkOOBO();

  const returnWindowsFetched = returnWindowsStatus === FETCHING_STATUS.FULFILLED;
  const nearStoresFetched = nearStoresStatus === FETCHING_STATUS.FULFILLED;
  const nearStoresNotFetched = nearStoresStatus !== FETCHING_STATUS.FULFILLED;

  const isPickup = [
    VIEWS.ALL_WINDOWS_PICKUP,
    VIEWS.RECOMMENDED_PICKUP,
    VIEWS.AVAILABLE_PICKUP_DATES,
  ].includes(currentView);

  const showWindowChoosing = [
    VIEWS.RECOMMENDED_PICKUP,
    VIEWS.ALL_WINDOWS_PICKUP,
    VIEWS.RECOMMENDED_RETURN,
    VIEWS.ALL_WINDOWS_RETURN,
  ].includes(currentView);

  const showAllWindows = [VIEWS.ALL_WINDOWS_PICKUP, VIEWS.ALL_WINDOWS_RETURN].includes(
    currentView
  );

  const isAutoSchedule = availableStores?.autoScheduleReturnEnabled;

  const wrapperClassNames = `schedule-view ${
    currentView === VIEWS.RETURN_QUESTION ? "return-question-view" : ""
  } ${currentView === VIEWS.SUBSCRIPTION_OFFER ? "subscription-question-view" : ""} ${
    isAutoSchedule ? "auto-schedule" : ""
  }`;

  const choosingWindowsTitle = isPickup ? SCHEDULE.pickupTitle : SCHEDULE.returnDelivery;

  let choosingWindowsButton;

  switch (true) {
    case isPickup && !isAutoSchedule:
      choosingWindowsButton = SCHEDULE.setPickupTime;
      break;
    case !isPickup && !isAutoSchedule:
      choosingWindowsButton = SCHEDULE.setReturnTime;
      break;
    case isAutoSchedule:
      choosingWindowsButton = SCHEDULE.setAutoScheduleTime;
      break;
    default:
      choosingWindowsButton = SCHEDULE.setAutoScheduleTime;
      break;
  }

  const allWindowsRef = useRef(null);
  const scheduleRef = useRef(null);
  const recommendedPickupRef = useRef(null);
  const recommendedReturnRef = useRef(null);
  const createSubscriptionRef = useRef(null);
  const chooseReturnRef = useRef(null);

  return {
    nearStoresFetched,
    nearStoresNotFetched,
    returnWindowsFetched,
    showWindowChoosing,
    showAllWindows,
    returnWindowsStatus,

    wrapperClassNames,
    choosingWindowsTitle,
    choosingWindowsButton,

    isAutoSchedule,
    isPickup,
    isOOBOManager,

    allWindowsRef,
    scheduleRef,
    recommendedPickupRef,
    recommendedReturnRef,
    createSubscriptionRef,
    chooseReturnRef,
  };
};
