import {type IOrderData, ServiceCategoryTypes} from "../types";
import {IOnlineOrderServices} from "./../thunks/fetchOnlineOrderServices";
import {setCategorySelection} from "./setCategorySelection";

type EvaluatePossibleService = (data: {
  state: IOrderData;
  payload: IOnlineOrderServices;
}) => void;

export const evaluatePossibleService: EvaluatePossibleService = ({state, payload}) => {
  const possibleSelectedService = state.orderBuilder.services.possibleSelectedService;
  const isServiceMultiselectFeatureFlagEnabled =
    payload.isServiceMultiselectFeatureFlagEnabled;

  switch (possibleSelectedService?.type) {
    case ServiceCategoryTypes.DRY_CLEANING:
      setCategorySelection({
        state,
        newValue:
          !!state.orderBuilder.services.categories.dryCleaning.availableForDelivery,
        categoryType: ServiceCategoryTypes.DRY_CLEANING,
      });
      break;
    case ServiceCategoryTypes.LAUNDRY: {
      const selectedService = payload.services.find(
        (service) =>
          service.id === possibleSelectedService.service?.id &&
          service.prices[0]?.id === possibleSelectedService.service.prices[0]?.id
      );
      if (!!selectedService?.id) {
        if (isServiceMultiselectFeatureFlagEnabled) {
          state.orderBuilder.services.customerSelectedServices = [
            {...selectedService, customerSelectedModifiers: null},
          ];
        } else {
          state.orderBuilder.services.selectedService = selectedService;
        }
      }
      break;
    }
    default: {
      break;
    }
  }
};
