import {FC} from "react";
import {ButtonVariant, Button} from "components/common/buttons/Button";
import styles from "./carePreferences.module.scss";

export const CarePreferences: FC<{preferences: string; onEdit: () => void}> = ({
  preferences,
  onEdit,
}) => {
  return (
    <div className={styles.carePreferencesWrapper}>
      <div>
        <h3>Care preferences</h3>
        <Button
          variant={ButtonVariant.UNDERLINED}
          className={styles.editButton}
          onClick={onEdit}
        >
          Edit
        </Button>
      </div>
      <p>{preferences}</p>
    </div>
  );
};
