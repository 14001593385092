export const EN_LOCALE = {
  address: {
    errors: {
      hasActiveSubscription: "Address used for recurring order",
    },
  },
  business: {
    errors: {
      businessCapabilitiesCouldNotBeFetched:
        "We were unable to fetch the delivery and self-serve capabilities of this business, please refresh and try again",
    },
  },
  button: {
    addPromo: "Add Promo",
    applyCredit: "Apply Credit",
    applyPromo: "Apply Promo",
    applyRewards: "Apply Rewards",
    closeDrawer: "Close drawer",
    continue: "Continue",
    decreaseBags: "Decrease the number of bags",
    edit: "Edit",
    enterCardAndPay: "Enter card & pay",
    email: "Email",
    increaseBags: "Increase the number of bags",
    manage: "Manage",
    ooboCTA: "Please click submit to order on behalf of customer.",
    orderAgain: "Order Again",
    pay: "Pay",
    removeCredit: "Remove Credit",
    removePromo: "Remove Promo",
    removeRewards: "Remove Rewards",
    resendOTP: "Send code again",
    save: "Save",
    savePreferences: "Save Preferences",
    sendVerificationCode: "Send Verification Code",
    showVerificationCode: "Show Verification Code",
    submitOTP: "Submit Code",
    update: "Update",
  },
  customer: {
    errors: {
      lastOrderTypeCouldNotBeFetched:
        "We were unable to fetch the last order type for this customer, please refresh and try again",
    },
  },
  label: {
    accountCredited: "Account Credited",
    accountVerification: "Verify Account",
    appliedRoundUp: "Applied Round Up",
    arrow: "Arrow",
    back: "Back",
    canceled: "Canceled",
    cross: "Cross",
    custom: "Custom",
    changePaymentMethod: "Change Payment Method",
    chevron: "Chevron",
    closeAddressDetailsDialog: "Close Address Details Dialog",
    commercialDcaLicense: "DCA # - Commercial:",
    confirmAddress: "Confirm Address",
    creditApplied: "Credit applied",
    creditCard: "Credit Card",
    dcaLicense: "DCA # - Retail:",
    deliveredAt: "Delivered at",
    delivery: "Delivery",
    details: "Details",
    discountApplied: "Discount applied",
    dryCleaning: "Dry Cleaning",
    eachWay: "each way",
    email: "Email",
    enterCardAndPay: "Enter card & pay",
    estimatedArrival: "Estimated arrival",
    firstName: "First Name",
    free: "Free",
    lastName: "Last Name",
    markedForInvoicing: "Marked for invoicing",
    minimalPrice: (price: string) => `(Minimum ${price})`,
    navigationMenu: "Navigation menu",
    off: "off",
    nextDay: "Next day",
    order: "Order",
    orderNotes: "Order Notes",
    orderNotesExample:
      "e.g. The grey sweater has a stain on it, please use stain remover.",
    orderTotal: "Order Total",
    paidViaInvoice: "Paid via Invoice",
    payment: "Payment",
    paymentMethod: "Payment Method",
    pencil: "Pencil",
    phone: "Phone",
    pickup: "Pickup",
    poweredByCents: "Powered by Cents",
    preferences: "Preferences",
    previousDay: "Previous day",
    promo: "Promo",
    promoApplied: "Promo Applied",
    promoCode: "Promo Code",
    orderLocation: "Order Location",
    recurringOrderDiscount: "Recurring Order Discount",
    rewardsApplied: "Rewards applied",
    rememberTipForRecurring: "Remember tip for recurring orders",
    searchAddress: "Search address",
    selectPaymentMethod: "Select Payment Method",
    serviceFee: "Service fee",
    showStoreInfo: "Store Info",
    storeInfo: "Store Info",
    subtotal: "Subtotal",
    tax: "Tax",
    // To Be Determined
    TBD: "TBD",
    tip: "Tip",
    totalDue: "Total Due",
    totalPaid: "Total Paid",
    yourDriver: "Your Driver",
    yourOrder: "Your Order",
  },
  messages: {
    accountLinkingIssue: "Could not check your account linked to your phone number",
    accountVerification: "Verify Your Account",
    addTipForTheStaff: "Add a tip for the staff",
    applyPromoCode: "Apply Promo Code",
    codeSent: "Code sent!",
    genericError: "Something went wrong",
    howMuchTip: "How much would you like to tip the staff?",
    loginRequirement: "Verify your account to login",
    optInCall: "I’ve opted back in",
    orderCanceled: "Order canceled!",
    orderPaidSuccessfully: "Order paid successfully!",
    otpCTA: "Enter the 6-digit code we sent to",
    otpInstruction: "We’ll send you a text with a code to create your account.",
    paymentFailed: "Payment Failed! Please try again.",
    phoneNumberCTA: "What’s your phone number?",
    phoneNumberInput: "Phone number input",
    phoneNumberPurpose:
      "We’ll only use it to verify your identity and provide updates about your order.",
    preferencesVisibilityDescription:
      "We’ll remember your preferences. You can always access them via Account Settings and when checking out.",
    preferencesVisibilityOption: "Don't show me this page again",
    promoRemoved: "Promo Removed!",
    rewardsApplied: "Rewards applied!",
    rewardsPointsRemoved: "Rewards points removed!",
    welcomeBack: "Welcome back,",
  },
  order: {
    errors: {
      submitError: "Could not submit the order!",
      updateError: "Error while updating your order",
    },
    labels: {
      deliveryScheduled: "Delivery Scheduled",
      driverHeaded: "Driver is headed your way",
      driverIsOnTheWay: "Driver is on the way",
      driverLeftOrderAtDoor: "The driver left your order at your door",
      laundryIsEnRoute: "Laundry is en route for processing",
      orderComplete: "Order Complete",
      orderHasBeenLeftAtDoor: "Your order has been left at your door",
      pickedUp: "Picked Up",
      pickupScheduled: "Pickup scheduled",
      processing: "Processing",
      proofOfDelivery: "Proof of delivery",
      readyForPickupInStore: "Ready for pickup in-store",
      yourNextPickup: "Your Next Pickup:",
    },
  },
  payment: {
    errors: {
      paymentIntentCaptureError:
        "Payment declined. Please use a different payment method.",
    },
    messages: {
      paymentCondition:
        "Your card will not be charged until your order is picked up in-store or ready for delivery.",
    },
  },
};
