export const styles = {
  wrapper: {
    alignItems: "center",
    justifyContent: "center",
    height: "67px",
    sx: {
      position: "sticky",
      top: 0,
      zIndex: 9,
    },
  },
  menu: {
    sx: {
      position: "absolute",
      left: 3,
      cursor: "pointer",
    },
  },
  rightContainer: {
    sx: {
      position: "absolute",
      right: 3,
    },
  },
  logo: {
    sx: {
      top: -1,
      left: -1,
      position: "relative",
    },
  },
  logoWrapper: {
    flexDirection: "row",
    alignSelf: "baseline",
  },
  deliveryButton: {
    sx: {
      backgroundColor: "#3790F4",
      borderRadius: 23.48,
      width: [120, 170],
      height: [40, 47],
      fontWeight: 400,
      fontSize: [12, 14],
      fontFamily: "inherit",
    },
  },
  deliveryLink: {
    sx: {
      color: "#3790F4",
      textDecoration: "underline",
      fontSize: "14px",
      cursor: "pointer",
    },
  },
  headerName: {
    color: "NEW_TEXT_PRIMARY_BLUE",
    fontWeight: 600,
    fontFamily: "Inter",
    fontSize: "18px",
    lineHeight: "22px",
    textAlight: "center",
  },
} as const;

export const selfServeHeaderStyles = {
  ...styles,
  wrapper: {
    ...styles.wrapper,
    justifyContent: "flex-start",
    sx: {
      ...styles.wrapper.sx,
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.20)",
    },
  },
  menu: {
    margin: "0 20px",
  },
  headerName: {
    ...styles.headerName,
    color: "TEXT_GREY",
  },
} as const;
