import {FC, useRef} from "react";
import {useFlags} from "launchdarkly-react-client-sdk";
import {
  ScheduleFormInputTypes,
  ScheduleFormPickupDeliveryInput,
} from "./ScheduleFormPickupDeliveryInput";

export type InputWithNavigation = {
  action: () => void;
  id: string | number;
  isSkeleton: boolean;
  current: unknown;
  isOnDemandDelivery: boolean;
  isScheduleWhenReady: boolean;
  isAutoSchedule: boolean;
  price: string | number | null;
  duration: string | number | null;
  timing: {
    date: string;
    interval: string;
  };
  windowConflicts?: string[];
  maxStops?: number | null;
  shiftName?: string;
  shiftColor?: string;
};

export interface IScheduleForm {
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  onChange?: () => void;
  routeData?: Record<ScheduleFormInputTypes, InputWithNavigation>;
  theme: Record<string, string>;
  selectedInterval?: number | null;
  forwardedRef?: React.RefObject<HTMLFormElement>;
}

const SUBSCRIPTION_INTERVALS = {
  "One Time": null,
  Weekly: 1,
  "Every two weeks": 2,
  "Every four weeks": 4,
};

export const ScheduleForm: FC<IScheduleForm> = ({
  onSubmit,
  onChange,
  routeData,
  theme,
  selectedInterval = null,
  forwardedRef,
}) => {
  const {serviceMultiselect, recurringSubscriptionsV2} = useFlags();
  const defaultRef = useRef<HTMLFormElement>(null);
  const scheduleFormRef = forwardedRef || defaultRef;
  const isDefaultChecked = useRef<boolean>(true);

  const onChangeInputs = (): void => {
    isDefaultChecked.current = false;
    onChange?.();
  };

  const isRecurringOrderPossible = !(serviceMultiselect && !recurringSubscriptionsV2);

  return (
    <form ref={scheduleFormRef} id="schedule-form" onSubmit={onSubmit}>
      {routeData &&
        Object.keys(routeData).length > 0 &&
        Object.entries(routeData).map(([type, {action, ...assets}]) => (
          <ScheduleFormPickupDeliveryInput
            key={`subscriptionForm:${type}:navi`}
            type={type as ScheduleFormInputTypes}
            onChange={onChangeInputs}
            action={action}
            assets={assets}
            theme={theme}
          />
        ))}
      {isRecurringOrderPossible && (
        <fieldset>
          <legend>Frequency</legend>
          {Object.entries(SUBSCRIPTION_INTERVALS).map(([title, value]) => (
            <label
              className="with-hidden-radio"
              key={`subscriptionForm:${title}:${value}`}
            >
              <input
                defaultChecked={isDefaultChecked.current && value === selectedInterval}
                type="radio"
                name="interval"
                onChange={onChangeInputs}
                value={String(value)}
              />
              <span>{title}</span>
            </label>
          ))}
        </fieldset>
      )}
    </form>
  );
};
