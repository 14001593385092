import React from "react";
import PropTypes from "prop-types";
import {Flex, Text} from "rebass/styled-components";
import {orderThemeColors, defaultFontFamily} from "components/online-order/orderTheme";

const ToastError = ({message}) => {
  const error = typeof message === "string" ? message : "Something went wrong";
  return (
    <Flex style={{alignItems: "center", justifyContent: "flex-start"}}>
      <Text style={{color: orderThemeColors.textMain, fontFamily: defaultFontFamily}}>
        {error}
      </Text>
    </Flex>
  );
};

ToastError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ToastError;
