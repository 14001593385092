import {FetchingStatus} from "types/common";
import {ISelfServe} from "../types";

const initialData: ISelfServe = {
  theme: {
    data: {
      active: null,
      boldFont: "",
      borderRadius: "",
      businessId: null,
      businessName: "",
      createdAt: "",
      id: null,
      logoUrl: "",
      normalFont: "",
      primaryColor: "",
      secondaryColor: "",
      updatedAt: "",
    },
    fetchingStatus: FetchingStatus.Initial,
    error: {
      text: "",
      code: "",
    },
  },
};

export default initialData;
