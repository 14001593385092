import {useCallback, useState} from "react";
import SelfServeWrapper from "features/order/self-serve/components/wrapper/SelfServeWrapper";
import {IMachine} from "features/order/self-serve/types";
import {DockModal} from "components/common";
import SelfOrder from "components/self-order/SelfOrder";
import type {UseAddCreditsPayload} from "api/queries/useAddCredits";
import {MachineTypes} from "constants/index";
import type {ICustomer} from "types/customer";

export enum SelfServeOrderModalComponents {
  SELF_ORDER = "SELF_ORDER",
  SELF_SERVE = "SELF_SERVE",
}

const SELF_SERVE_MODAL_HEADERS: Record<string, string> = {
  [MachineTypes.WASHER]: "New Washer",
  [MachineTypes.DRYER]: "New Dryer",
};

interface SelfServeOrderModalProps {
  isOpen: boolean;
  toggle: () => void;
  header?: string | null;
  turnId?: number;
  balanceInDollars?: number | null;
  customerInfo: Pick<ICustomer, "id" | "paymentMethods" | "addresses"> | null;
  refetchBalance: UseAddCreditsPayload["refetch"];
  modalType: SelfServeOrderModalComponents;
  hash?: string | null;
}

export const SelfServeOrderModal: React.FC<SelfServeOrderModalProps> = ({
  isOpen,
  toggle,
  header,
  balanceInDollars,
  customerInfo,
  refetchBalance,
  hash,
  ...props
}) => {
  const [turnId, setTurnId] = useState<number | null>(props.turnId ?? null);
  const [modalType, setModalType] = useState(props.modalType);
  const [modalHeader, setModalHeader] = useState<string | null>(header ?? null);
  const [machineDetails, setMachineDetails] = useState<IMachine | null>(null);

  const onOpenSelfOrder = (turnId: number | null) => {
    setTurnId(turnId);
    setModalType(SelfServeOrderModalComponents.SELF_ORDER);
    setModalHeader(
      machineDetails ? `${machineDetails.prefix}-${machineDetails.name}` : ""
    );
  };

  const onToggle = useCallback(() => {
    toggle();
    setTurnId(props.turnId ?? null);
    setModalType(props.modalType);
    setModalHeader(header ?? null);
    setMachineDetails(null);
  }, [header, props.modalType, props.turnId, toggle]);

  const onSetMachineDetails = useCallback((details: IMachine) => {
    setMachineDetails(details);
    setModalHeader(SELF_SERVE_MODAL_HEADERS[details.model.type]);
  }, []);

  return (
    <DockModal
      header={modalHeader}
      isOpen={isOpen}
      toggle={onToggle}
      fullWidth
      size={1}
      showExitIcon
      dockZindex={9}
    >
      {modalType === SelfServeOrderModalComponents.SELF_SERVE && hash && (
        <SelfServeWrapper
          isModal
          hash={hash}
          setMachineDetails={onSetMachineDetails}
          openSelfOrder={onOpenSelfOrder}
          refetchBalance={refetchBalance}
          balanceInDollars={balanceInDollars}
          customerInfo={customerInfo}
        />
      )}
      {modalType === SelfServeOrderModalComponents.SELF_ORDER && (
        <SelfOrder
          isModal
          id={turnId}
          openSelfOrder={onOpenSelfOrder}
          balanceInDollars={balanceInDollars}
          customerInfo={customerInfo}
          refetchBalance={refetchBalance}
          machineId={machineDetails?.id as number | undefined}
        />
      )}
    </DockModal>
  );
};
