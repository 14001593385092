import {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Loader} from "components/common";
import {ROUTES as ROOT_ROUTES} from "routes/constants";
import {ROUTES as ORDER_ROUTES} from "routes/order/business/constants";

export const ServiceFirstOrderCreation = () => {
  const history = useHistory();
  const {storeId} = useParams<{storeId: string}>();

  useEffect(() => {
    history.push(`/${ROOT_ROUTES.newOrder}/${storeId}/${ORDER_ROUTES.home}`);
  }, [history, storeId]);

  return <Loader />;
};
