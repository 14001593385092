import {ChangeEvent, FC} from "react";
import {Input} from "components/common/forms";
import {InputType} from "components/common/forms/Input/types";
import {IPreferencesOption, PreferenceType} from "./types";

interface IAccountCarePreferencesItem {
  type: PreferenceType;
  itemId: number;
  id: number;
  value: string;
  isDefault: boolean;
  selected: boolean;
  options: IPreferencesOption[];
  updateCheckedView: (type: PreferenceType, e: ChangeEvent<HTMLInputElement>) => void;
  selectionId?: number;
}

export const AccountCarePreferencesItem: FC<IAccountCarePreferencesItem> = ({
  type,
  id,
  value,
  isDefault,
  selected,
  selectionId,
  options,
  itemId,
  updateCheckedView,
}) => {
  const isNoOneSelected: boolean =
    type === PreferenceType.SINGLE &&
    options.every(({selected}: IPreferencesOption) => !selected);
  const isDefaultSelected: boolean =
    (type === PreferenceType.SINGLE && isDefault && isNoOneSelected) || selected;
  const isDefaultChecked: boolean =
    (type === PreferenceType.SINGLE && isDefault && selected) || selected;

  return (
    <Input
      type={type === PreferenceType.SINGLE ? InputType.radio : InputType.checkbox}
      onChange={(event: ChangeEvent<HTMLInputElement>) => updateCheckedView(type, event)}
      className={`${isDefaultSelected ? "checked" : ""} preference-item`}
      name={type === PreferenceType.SINGLE ? String(itemId) : `${String(itemId)}:${id}`}
      required={false}
      value={`${id}:${selectionId}:${selected}:${type}`}
      defaultChecked={isDefaultChecked}
    >
      {value}
    </Input>
  );
};
