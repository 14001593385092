import {useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe, type PaymentMethod} from "@stripe/stripe-js";
import {Button, Flex, Heading, Text} from "rebass/styled-components";
import WalletPaySelection from "components/account/AccountPayment/AddNewCard/components/WalletPaySelection";
import {DockModal, Loader} from "components/common";
import Payment from "components/online-order/online-order-form/finishing-up/payment";
import {STRIPE_PUBLIC_KEY} from "utils/config";
import {addNewCard, saveCardToState} from "utils/payment";
import {ICustomer, IPaymentMethod} from "types/customer";
import {IAddCredit} from "../../types";
import TogglePrice from "../togglePrice";
import StripeElements from "./StripeElements";
import styles from "./styles";


const DEFAULT_AMOUNT = 30;
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY as string);

interface IChooseAmountProps {
  customer: Pick<ICustomer, "id" | "paymentMethods" | "addresses"> | null;
  storeId?: number | null;
  machine?: {
    id: number | null;
    name: string;
  } | null;
  sendAmount: (data: IAddCredit) => void;
  showModal: boolean;
  toggleShowModal: (status: boolean) => void;
}

export interface PaymentDataType {
  payment: PaymentMethod;
  rememberPaymentMethod: boolean;
}

const ChooseAmount = ({
  sendAmount,
  showModal,
  toggleShowModal,
  customer,
  storeId,
  machine,
}: IChooseAmountProps) => {
  const [amountState, setAmountState] = useState(DEFAULT_AMOUNT);
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState(
    customer?.paymentMethods ?? []
  );
  const [paymentMethodTokenState, setPaymentMethodTokenState] = useState("");
  const [showNewPaymentMethod, setShowNewPaymentMethod] = useState(false);
  const [showPaymentMethodList, setShowPaymentMethodList] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (customer?.paymentMethods?.length) {
      setCustomerPaymentMethods(customer.paymentMethods);
      setPaymentMethodTokenState(customer.paymentMethods[0].paymentMethodToken);
    }
  }, [customer?.paymentMethods]);

  const sendAmountCredit = () => {
    if (amountState) {
      sendAmount({
        credits: amountState,
        paymentMethodToken: paymentMethodTokenState,
        ...(storeId && {storeId}),
      });
      setAmountState(DEFAULT_AMOUNT);
      toggleShowModal(!showModal);
    }
  };

  /**
   * Handle wallet pay selection and add the appropriate funds to the customer's credit account
   */
  const handleWalletPaySelection = (paymentMethod: PaymentMethod) => {
    if (storeId) {
      sendAmount({
        credits: amountState,
        paymentMethodToken: paymentMethod.id,
        storeId,
      });
      setAmountState(DEFAULT_AMOUNT);
      toggleShowModal(!showModal);
    }
  };

  const onPaymentSave = (paymentInfo: PaymentDataType) => {
    if (paymentInfo.rememberPaymentMethod) {
      setLoading(true);
      addNewCard(
        paymentInfo,
        customer?.addresses[0],
        customer?.id,
        (response: IPaymentMethod[]) => {
          setCustomerPaymentMethods(response);
          setPaymentMethodTokenState(paymentInfo.payment.id);
          setShowPaymentMethodList(true);
          setShowNewPaymentMethod(false);
          setLoading(false);
        },
        () => {
          setShowNewPaymentMethod(false);
          setLoading(false);
        }
      );
    } else {
      saveCardToState(
        paymentInfo,
        customer?.id,
        customerPaymentMethods,
        (paymentMethods: IPaymentMethod[]) => {
          setPaymentMethodTokenState(paymentInfo.payment.id);
          setCustomerPaymentMethods(paymentMethods);
          setShowPaymentMethodList(true);
          setShowNewPaymentMethod(false);
        }
      );
    }
  };

  const onPaymentCancel = () => {
    window.scrollTo(0, 0);
    setShowNewPaymentMethod(false);
  };

  const renderWalletPaySelection = () => {
    if (customerPaymentMethods.length === 0) {
      return (
        // @ts-ignore: version conflict between stripe and react
        <Elements stripe={stripePromise}>
          <WalletPaySelection
            insideNewCardForm={true}
            saveWalletPaymentMethod={handleWalletPaySelection}
            label={"Add credit to account"}
            amount={amountState * 100}
          />
        </Elements>
      );
    }
  };

  return (
    <>
      <DockModal
        header="Add Funds"
        isOpen={showModal}
        toggle={() => {
          toggleShowModal(!showModal);
        }}
        dockZindex={999}
      >
        <Flex sx={styles.wrapperModal}>
          <Flex sx={styles.wrapperContent}>
            <Heading sx={styles.heading}>Choose amount</Heading>
            <Text sx={styles.normalText}>
              How much would you like to add to your credit balance? You can use your
              credit balance to pay for self-serve or full service laundry or
              over-the-counter items.
            </Text>
            <TogglePrice amount={amountState} setAmount={setAmountState} />
          </Flex>
          <Flex sx={styles.wrapperBottom}>
            <Payment
              isLastWrapper
              showHeader={false}
              customer={{
                ...customer,
                centCustomerId: customer?.id, // centsCustomerId is required inside PaymentMethodList
              }}
              setShowPaymentMethods={setShowPaymentMethodList}
              onShowNewPaymentMethod={() => setShowNewPaymentMethod(true)}
              customerPaymentMethods={customerPaymentMethods}
              showPaymentMethods={showPaymentMethodList}
              onPaymentSelection={setPaymentMethodTokenState}
              paymentToken={paymentMethodTokenState}
              totalAmount={0} // this is to ensure we show "amount pending" when choosing apple/google pay
              machine={machine}
            />
            <StripeElements
              showNewPaymentMethod={showNewPaymentMethod}
              onPaymentSave={onPaymentSave}
              onPaymentCancel={onPaymentCancel}
            />
            {paymentMethodTokenState && (
              <Button
                disabled={!amountState}
                onClick={sendAmountCredit}
                sx={styles.saveButton}
              >
                ADD {amountState && `$${amountState}`}
              </Button>
            )}
            {renderWalletPaySelection()}
          </Flex>
        </Flex>
      </DockModal>
      {loading && <Loader />}
    </>
  );
};

export default ChooseAmount;