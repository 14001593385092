import {httpClient} from "api";
import {AxiosPromise} from "axios";
import {
  IReferralProgramInfo,
  IReferralValidateCodeResponse,
} from "components/referral/redux/types";

export const fetchReferralProgramInfo = ({
  businessId,
  centsCustomerId,
}: {
  businessId: number;
  centsCustomerId: number;
}): AxiosPromise<IReferralProgramInfo> => {
  return httpClient({
    method: "GET",
    url: `/referrals/info/${businessId}`,
    params: {centsCustomerId},
  });
};

export const validateReferralCode = ({
  businessId,
  centsCustomerId,
  referralCode,
}: {
  businessId: number | string;
  centsCustomerId: number;
  referralCode: string;
}): AxiosPromise<IReferralValidateCodeResponse> => {
  return httpClient({
    method: "GET",
    url: `/referrals/validate/${businessId}/${referralCode}`,
    params: {centsCustomerId},
  });
};
