import {Suspense, useEffect} from "react";
import {
  Landing,
  OrderSummary,
  PageNotFound,
  SelfOrder,
  SelfServeWrapper,
  VerifyAccount,
  ReferralValidationPage,
} from "components";
import {Route, Switch, useLocation} from "react-router-dom";
import {NewOrderRoute} from "routes/newOrder";
import {ShortOrderRoute} from "routes/o";
import {OrderRoute} from "routes/order";
import {SelfServeHome} from "components/SelfServeHome/SelfServeHome";
import {Loader} from "components/common";
import {CentsCustomerProvider} from "components/common/providers/CentsCustomerProvider";
import {PrivateRoute} from "components/common/providers/PrivateRoute";
import {Welcome} from "components/welcome/Welcome";
import {ROUTES} from "./constants";

export const RootRoute = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, [location]);

  const SELF_SERVE_ORDERING_ROUTES = [
    <Route
      key="self-serve-wrapper-path"
      path="/self-serve/:uniqueCode"
      exact
      component={SelfServeWrapper}
    />,
    <PrivateRoute
      key="self-order-path"
      path="/self-order/:id"
      exact
      component={SelfOrder}
    />,
  ];

  return (
    <Suspense fallback={<Loader />}>
      <CentsCustomerProvider>
        <Switch>
          <Route path={`/${ROUTES.order}`} component={OrderRoute} />
          <PrivateRoute
            path="/order-summary/:orderToken"
            exact
            component={OrderSummary}
          />
          <PrivateRoute path="/o/:serviceOrderId" exact component={ShortOrderRoute} />
          <PrivateRoute
            path="/refer/:businessId/:referralCode"
            exact
            component={ReferralValidationPage}
          />
          <Route path="/verify-account" exact component={VerifyAccount} />
          <Route path={`/${ROUTES.newOrder}`} component={NewOrderRoute} />
          <PrivateRoute
            path="/business/:businessId/home"
            exact
            component={SelfServeHome}
          />
          <PrivateRoute path="/" exact component={Landing} />
          {SELF_SERVE_ORDERING_ROUTES}
          <PrivateRoute path="/business/:businessId/welcome" exact component={Welcome} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </CentsCustomerProvider>
    </Suspense>
  );
};
