import {CSSProperties, FC} from "react";
import {Box, Flex, Text} from "rebass/styled-components";

export const Loader: FC<{
  loaderColor?: string;
  style?: CSSProperties;
  width?: string;
  customHeader?: string;
}> = ({loaderColor = "primary", style, width, customHeader}) => {
  return (
    <Flex
      flexDirection="column"
      fontFamily="primary"
      style={{
        height: "var(--app-height)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(255, 255, 255, 1)",
        position: "absolute",
        zIndex: 999999999,
        top: "0",
        left: "0",
        ...style,
      }}
      width={width || "100%"}
      className="loader"
    >
      <div className="spinner">
        <Box className="rect1" bg={loaderColor} />
        <Box className="rect2" bg={loaderColor} />
        <Box className="rect3" bg={loaderColor} />
        <Box className="rect4" bg={loaderColor} />
        <Box className="rect5" bg={loaderColor} />
      </div>
      <Text className="color-transition" mt="1rem" color={loaderColor}>
        {customHeader || "Loading"}
      </Text>
    </Flex>
  );
};
