import {useState} from "react";
import {CustomLogo, Loader} from "components/common";
import {Drawer} from "components/common/drawers/Drawer";
import {ThemeProvider} from "components/common/providers/ThemeProvider";
import {EN_LOCALE} from "locales/en";
import VerifyAccountForm from "../VerifyAccountForm";
import styles from "./verifyUserModal.module.scss";

interface VerifyUserModalProps {
  isOpen: boolean;
  fetchingSubscriptions: boolean;
  onSuccess: () => Promise<void>;
  businessId: number | null;
  storeId: number | null;
  onCustomBack?: () => void;
  provideBackOption?: boolean;
}

export const VerifyUserModal = ({
  isOpen,
  fetchingSubscriptions,
  onSuccess,
  businessId,
  storeId,
}: VerifyUserModalProps) => {
  const [loading, setLoading] = useState(false);
  const [showOTPScreen, setShowOTPScreen] = useState(false);
  const [error, setError] = useState("");
  const [OTP, setOTP] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  return (
    <Drawer
      isShowingDrawer={isOpen}
      title={EN_LOCALE.label.accountVerification}
      fullWidth={true}
      drawerClassName={styles.drawer}
      titleClassName={styles.drawerTitle}
      ModalProps={{className: styles.drawerWrapper}}
    >
      <ThemeProvider className={styles.wrapper}>
        {(loading || fetchingSubscriptions) && <Loader />}
        <div className={styles.content}>
          <div id="logo-container">
            <CustomLogo />
          </div>
          <div className={styles.form}>
            <VerifyAccountForm
              onSuccess={onSuccess}
              setLoading={setLoading}
              setError={setError}
              showOTPScreen={showOTPScreen}
              setShowOTPScreen={setShowOTPScreen}
              OTP={OTP}
              setOTP={setOTP}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              businessId={businessId}
              storeId={storeId}
            />
            {error ? <span className={styles.error}>{error}</span> : null}
          </div>
        </div>
      </ThemeProvider>
    </Drawer>
  );
};
