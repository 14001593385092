import {FC, useEffect} from "react";
import {useFlags} from "launchdarkly-react-client-sdk";
import {DryCleaningCategoryProps} from "components/common/forms/services/DryCleaningCategory/DryCleaningCategory";
import {LaundryCategoryFormProps} from "components/common/forms/services/LaundryCategory/LaundryCategoryForm";
import {StoreServicesPricing} from "components/orderCreation/serviceFirstFlow/home/components/ServicesSection/components/StoreServicesPricing";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {fetchCustomerInfoThunk} from "state/redux/slices/customer/thunks/fetchCustomerInfoThunk";
import {orderSelectors} from "state/redux/slices/order";
import {getIsDryCleaningAvailableForDelivery} from "state/redux/slices/order/selectors/servicesSelector";
import {getStoreId} from "state/redux/slices/order/selectors/storeSelectors";
import {fetchDeliveryServices} from "state/redux/slices/order/thunks/fetchDeliveryServices";
import {DryCleaningCategory} from "../DryCleaningCategory";
import {LaundryCategoryForm, LaundryCategory} from "../LaundryCategory";

export type ServiceCategoriesProps = DryCleaningCategoryProps & LaundryCategoryFormProps;

export const ServiceCategories: FC<ServiceCategoriesProps> = ({
  dryCleaningTurnAround,
  isDryCleaningCategorySelected,
  onDryCleaningCategoryToggle,
  ...laundryCategoryFormProps
}) => {
  const dispatch = useAppDispatch();
  const {serviceMultiselect} = useFlags();
  const isDryCleaningAvailableForDelivery = useAppSelector(
    getIsDryCleaningAvailableForDelivery
  );
  const postalCode = useAppSelector(orderSelectors.getCurrentCustomerAddress)?.postalCode;
  const storeId = useAppSelector(getStoreId);

  useEffect(() => {
    type FetchDetails = (data: {storeId: string | number; zipCode: string}) => void;
    const fetchServicesDetails: FetchDetails = async ({storeId, zipCode}) => {
      dispatch(fetchDeliveryServices({storeId, zipCode}));
      dispatch(fetchCustomerInfoThunk({storeId}));
    };

    if (storeId && postalCode) {
      fetchServicesDetails({storeId, zipCode: postalCode});
    }
  }, [dispatch, postalCode, storeId]);

  return (
    <div>
      {isDryCleaningAvailableForDelivery && (
        <DryCleaningCategory
          dryCleaningTurnAround={dryCleaningTurnAround}
          isDryCleaningCategorySelected={isDryCleaningCategorySelected}
          onDryCleaningCategoryToggle={onDryCleaningCategoryToggle}
        />
      )}
      {serviceMultiselect ? (
        <LaundryCategoryForm {...laundryCategoryFormProps} />
      ) : (
        <LaundryCategory />
      )}
      <StoreServicesPricing />
    </div>
  );
};
