import PropTypes from "prop-types";
import {A11yButton} from "components/common/buttons/A11yButton";
import {useThemeValues} from "hooks/useThemeValues";
import {SelectedCheckboxWithoutColor} from "assets/images";
import {ReactComponent as DisabledCheckbox} from "assets/images/Icon_Empty_Unselected.svg";
import "./styles.scss";

const ModifierButton = ({name, price, checked, onClick}) => {
  const {primaryColor} = useThemeValues();
  return (
    <A11yButton
      className="modifier-button-wrapper"
      onAction={onClick}
      aria-pressed={checked}
      aria-label={name}
    >
      <div className="modifier-text-checkbox">
        <div className="modifier-checkbox-wrapper">
          {checked ? (
            <SelectedCheckboxWithoutColor
              stroke={primaryColor}
              fill={primaryColor}
              className="modifier-checkbox"
            />
          ) : (
            <DisabledCheckbox className="modifier-checkbox" />
          )}
        </div>
        <p className="modifier-name">{name}</p>
      </div>
      <p className="modifier-price">{price}</p>
    </A11yButton>
  );
};

ModifierButton.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

ModifierButton.defaultProps = {
  name: "",
  price: "",
  checked: false,
  onClick: () => {},
};

export default ModifierButton;
