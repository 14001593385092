import {lazy} from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {PrivateRoute} from "components/common/providers/PrivateRoute";

const OrderBusinessRoute = lazy(() => import("routes/order/business"));
const OrderSummary = lazy(() => import("components/order-summary/new-index"));

export const OrderRoute = () => {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/business/:businessId`} component={OrderBusinessRoute} />
      <PrivateRoute path={`${path}/:serviceOrderId`} exact component={OrderSummary} />
      <Redirect to="/" />
    </Switch>
  );
};
