import {FC, HTMLAttributes, MouseEventHandler, ReactNode} from "react";
import {type Event, processActionByKeyboard} from "utils/a11y";

interface Props
  extends Omit<
    HTMLAttributes<HTMLDivElement>,
    "onClick" | "onKeyDown" | "role" | "tabIndex"
  > {
  children: ReactNode;
  disabled?: boolean;
  onAction: unknown;
}

/**
 * Unfortunately, adding `div` as a child element of button makes the markup invalid according to W3C:(
 *
 * So we have to do `div` like a button and do them according to the accessibility requirements.
 * For this purpose we have this component, which has the required attributes and can be extended in the future.
 */
export const A11yButton: FC<Props> = ({
  children,
  "aria-pressed": ariaPressed = false,
  onAction,
  disabled = false,
  ...elementProps
}) => {
  return (
    <div
      {...elementProps}
      {...(disabled
        ? {}
        : {
            onClick: onAction as MouseEventHandler<HTMLDivElement>,
            "aria-pressed": ariaPressed,
            role: "button",
            tabIndex: 0,
            onKeyDown: (event) =>
              processActionByKeyboard(
                event,
                onAction as (event: Event) => void | Promise<void>
              ),
          })}
    >
      {children}
    </div>
  );
};
