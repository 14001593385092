import {FC, lazy, useEffect} from "react";
import {Redirect, Route, Switch, useParams, useRouteMatch} from "react-router-dom";

import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderSelectors, orderThunks} from "state/redux/slices/order";
import {FETCHING_STATUS} from "constants/api";

const AccountCarePreferences = lazy(
  () => import("components/account/AccountCarePreferences")
);
const AccountAddresses = lazy(() => import("components/account/AccountAddresses"));
const AccountContact = lazy(() => import("components/account/AccountContact"));
const AccountMain = lazy(() => import("components/account/AccountMain"));
const AccountNotifications = lazy(
  () => import("components/account/AccountNotifications")
);
const AccountPayment = lazy(
  () => import("components/account/AccountPayment/PaymentCards")
);
const AddNewCardPage = lazy(() => import("components/account/AccountPayment/AddNewCard"));

export const AccountRoutesUrls = {
  CONTACT: "contact",
  ADDRESSES: "addresses",
  NOTIFICATIONS: "notifications",
  CARE_PREFERENCES: "care-preferences",
  PAYMENT: "payment",
  ADD_NEW_PAYMENT: "payment/add",
};

const AccountRoutes: FC = () => {
  const dispatch = useAppDispatch();
  const {fetchingStatus} = useAppSelector(orderSelectors.getOrderInitialData);
  const {path} = useRouteMatch();

  const {businessId: encodedBusinessId} = useParams<{
    businessId: string;
  }>();

  useEffect(() => {
    if (fetchingStatus === FETCHING_STATUS.INITIAL) {
      dispatch(
        orderThunks.getOrderInitialData({
          businessId: encodedBusinessId,
        })
      );
    }
  }, [fetchingStatus]);

  return (
    <Switch>
      <Route path={path} component={AccountMain} exact />
      <Route
        path={`${path}/${AccountRoutesUrls.CONTACT}`}
        component={AccountContact}
        exact
      />
      <Route
        path={`${path}/${AccountRoutesUrls.ADDRESSES}`}
        component={AccountAddresses}
        exact
      />
      <Route
        path={`${path}/${AccountRoutesUrls.NOTIFICATIONS}`}
        component={AccountNotifications}
        exact
      />
      <Route
        path={`${path}/${AccountRoutesUrls.CARE_PREFERENCES}`}
        component={AccountCarePreferences}
        exact
      />
      <Route
        path={`${path}/${AccountRoutesUrls.ADD_NEW_PAYMENT}`}
        component={AddNewCardPage}
        exact
      />
      <Route
        path={`${path}/${AccountRoutesUrls.PAYMENT}`}
        component={AccountPayment}
        exact
      />
      <Redirect to="/" />
    </Switch>
  );
};

export default AccountRoutes;
