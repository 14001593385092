import {httpClient} from "api";
import {AxiosResponse} from "axios";
import {ISelfServeOrderItem} from "features/allOrders/types";
import {IGenericResponseData, IRequestParams} from "types/common";

export const fetchSelfServeOrdersList: (
  params: IRequestParams
) => Promise<AxiosResponse<IGenericResponseData<ISelfServeOrderItem>, any>> = async (
  params
) => {
  return httpClient({
    method: "GET",
    url: "/live-status/orders/self-service",
    params,
  });
};
