import {FC, forwardRef, UIEventHandler} from "react";
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from "@material-ui/core/styles";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import cn from "classnames";
import {ThemeProvider as StyledThemeProvider} from "styled-components";
import {typography} from "components/online-order/orderTheme";
import {useAppSelector} from "state/redux/hooks";
import {businessSelectors} from "state/redux/slices/business";
import {orderSelectors} from "state/redux/slices/order";
import {applyTheme, getFilterClass, getTranslucentColor} from "utils/theme";
import defaultTheme from "constants/themes/theme";
import styles from "./themeProvider.module.scss";

type ThemeLevel = "business" | "store";

interface ThemeProviderProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  themeLevel?: ThemeLevel;
  className?: string;
}

export const ThemeProvider: FC<ThemeProviderProps> = forwardRef<
  HTMLDivElement,
  ThemeProviderProps
>(function ThemeProvider(
  {children, className, themeLevel = "store", ...containerProps},
  ref
) {
  const businessTheme = useAppSelector(businessSelectors.getBusinessTheme);
  const storeTheme = useAppSelector(orderSelectors.getTheme);
  const theme =
    (themeLevel === "store" && storeTheme) ||
    (businessTheme?.id ? businessTheme : defaultTheme);

  const filterClass = getFilterClass(theme?.primaryColor);
  const joinedTheme = applyTheme(defaultTheme, theme);
  const muiTheme = createTheme({...joinedTheme, ...typography, filterClass});
  const translucentPrimaryColor = getTranslucentColor(theme.primaryColor);

  return (
    <div
      style={{
        // @ts-expect-error because of the CSS variable and style type incompatibility
        "--theme-color": theme.primaryColor,
        "--theme-secondary-color": translucentPrimaryColor,
        "--theme-border-radius": theme.borderRadius,
        // @ts-check
      }}
      className={cn(styles.wrapper, className)}
      {...containerProps}
      ref={ref}
    >
      <MaterialThemeProvider theme={muiTheme}>
        <MuiThemeProvider theme={muiTheme}>
          <StyledThemeProvider theme={joinedTheme}>{children}</StyledThemeProvider>
        </MuiThemeProvider>
      </MaterialThemeProvider>
    </div>
  );
});
