import {useTheme} from "@material-ui/core/styles";
import {getLightenColor, getTranslucentColor} from "utils/theme";
import defaultTheme from "constants/themes/theme";

export const useThemeValues = () => {
  const theme = useTheme();

  const primaryColor = theme?.palette?.primary?.main || defaultTheme.colors.CENTS_BLUE;
  const translucentPrimaryColor = getTranslucentColor(primaryColor);
  const lightenPrimaryColor = getLightenColor(primaryColor);
  const filterClass = theme.filterClass || "filter-1";

  return {primaryColor, translucentPrimaryColor, lightenPrimaryColor, filterClass};
};
