import {useEffect} from "react";
import PropTypes from "prop-types";
import Dock from "react-dock";
import {Flex, Image, Text} from "rebass/styled-components";
import {A11yButton} from "components/common/buttons/A11yButton";
import useWindowSize from "hooks/useWindowSize";
import {GLOBAL_STYLES} from "styles/globalStyles";
import {ExitIcon, IconSmallArrowLeft} from "assets/images";
import {Loader} from "./Loader";

const DockModal = (props) => {
  const {
    isOpen,
    toggle,
    header,
    loading,
    children,
    provideBackOption,
    size,
    fixedSize,
    showExitIcon,
    onBackClick,
    headerTextColor,
    zIndex,
    dockZindex,
    closeOnOutsideClick,
    fullWidth,
    dockStyle,
  } = props;

  const [width] = useWindowSize();

  useEffect(() => {
    // stops the bg scroll when the dock is open
    document.querySelector("body").style.overflow = isOpen ? "hidden" : "visible";
  }, [isOpen]);

  return (
    <Dock
      fluid={!fixedSize}
      size={width < 550 || fullWidth ? size : fixedSize ? window.innerHeight : 1}
      position="bottom"
      dockStyle={{
        left: fullWidth ? "0" : "50%",
        zIndex,
        width: "100%",
        maxWidth: fullWidth ? "unset" : GLOBAL_STYLES.MAX_WIDTH_CONTAINER,
        transform: fullWidth ? "unset" : "translate(-50%, 0)",
        ...dockStyle,
      }}
      dimStyle={{
        backdropFilter: "blur(8px)",
        WebkitBackdropFilter: "blur(8px)",
        background: width < 550 || fullWidth ? "none" : "white",
      }}
      isVisible={isOpen}
      onVisibleChange={provideBackOption || closeOnOutsideClick ? toggle : null}
      zIndex={dockZindex}
    >
      {loading && <Loader style={{height: "100%"}} />}
      {(header || provideBackOption) && (
        <Flex {...styles.header.wrapper}>
          {provideBackOption ? (
            <A11yButton
              onAction={onBackClick ? onBackClick : toggle}
              style={{...styles.header.image.sx}}
            >
              <Image
                src={showExitIcon ? ExitIcon : IconSmallArrowLeft}
                alt="Dock Close"
              />
            </A11yButton>
          ) : null}
          {header && (
            <Text {...styles.header.text} color={headerTextColor}>
              {header}
            </Text>
          )}
        </Flex>
      )}
      {/* Render children only when the dock modal is open */}
      {isOpen ? children : null}
    </Dock>
  );
};

const styles = {
  header: {
    wrapper: {
      height: "67px",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "18px",
      sx: {
        position: "relative",
      },
    },
    text: {
      fontSize: "18px",
      fontWeight: 600,
    },
    image: {
      sx: {
        position: "absolute",
        top: "18px",
        left: "18px",
      },
    },
  },
};

DockModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  header: PropTypes.string,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  provideBackOption: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  size: PropTypes.number,
  fixedSize: PropTypes.bool,
  onBackClick: PropTypes.func,
  zIndex: PropTypes.number,
  dockZindex: PropTypes.number,
  fullWidth: PropTypes.bool,
  headerTextColor: PropTypes.string,
  dockStyle: PropTypes.object,
};

DockModal.defaultProps = {
  loading: false,
  provideBackOption: true,
  size: 0.75,
  fixedSize: false,
  showExitIcon: false,
  headerTextColor: "NEW_TEXT_PRIMARY_BLUE",
  zIndex: 1,
  dockZindex: 99999999,
  closeOnOutsideClick: false,
  fullWidth: false,
  dockStyle: {},
};

export default DockModal;
