import {httpClient} from "api";
import {AxiosResponse} from "axios";
import {
  IFullServiceOrderActiveItem,
  IFullServiceOrderFinishedItem,
} from "features/allOrders/types";
import {IGenericResponseData, IRequestParams} from "types/common";

export const fetchFullServiceOrdersFinishedList: (
  params: IRequestParams
) => Promise<
  AxiosResponse<IGenericResponseData<IFullServiceOrderFinishedItem>, any>
> = async (params) => {
  return httpClient({
    method: "GET",
    url: "/live-status/orders/full-service/completed",
    params,
  });
};

export const fetchFullServiceOrdersActiveList: (
  params: IRequestParams
) => Promise<
  AxiosResponse<IGenericResponseData<IFullServiceOrderActiveItem>, any>
> = async (params) => {
  return httpClient({
    method: "GET",
    url: "/live-status/orders/full-service/active",
    params,
  });
};
