import {FC} from "react";
import cx from "classnames";
import {getPriceString, getMinPriceString} from "components/online-order/utils";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {
  getSelectedModifiers,
  getSelectedService,
} from "state/redux/slices/order/selectors/servicesSelector";
import {ILaundryService} from "state/redux/slices/order/types/orderBuilder";
import styles from "./laundryServiceButton.module.scss";

/**
 * @deprecated Details: https://cents.atlassian.net/browse/DEL-725
 *
 * Use src/components/common/checkboxes/ServiceCheckbox/ServiceCheckbox.tsx instead
 */
export const LaundryServiceButton: FC<{
  service: ILaundryService;
  isActive: boolean;
}> = ({service, isActive}) => {
  const servicePrice = getPriceString(service);
  const dispatch = useAppDispatch();
  const selectedService = useAppSelector(getSelectedService);
  const selectedModifiers = useAppSelector(getSelectedModifiers);
  const modifierCount = selectedModifiers?.length || 0;

  return (
    <button
      className={cx(styles.laundryServiceButton, {[styles.active]: isActive})}
      onClick={(e) => {
        e.stopPropagation();
        if (selectedService?.id === service.id) {
          dispatch(orderActions.setSelectedLaundryService(null));
          return;
        }

        dispatch(orderActions.setSelectedLaundryService(service));

        if (service.serviceModifiers.length) {
          dispatch(orderActions.toggleModifiersDrawer());
        }
      }}
    >
      <h3 className={styles.title}>{service.name}</h3>
      <span className={styles.price}>
        {service.hasMinPrice
          ? `${servicePrice} (minimum ${getMinPriceString(service)})`
          : servicePrice}
      </span>
      {modifierCount > 0 && isActive && (
        <div className={styles.modifiersLabelContainer}>
          <span className={styles.modifierText}>
            + {modifierCount} {modifierCount > 1 ? "add-ons" : "add-on"} selected
          </span>
        </div>
      )}
    </button>
  );
};
