import React, {useState} from "react";
import classNames from "classnames";
import {useHistory} from "react-router-dom";
import {CustomLogo} from "components/common";
import {A11yButton} from "components/common/buttons/A11yButton";
import {BusinessRouteProvider} from "components/common/providers/BusinessRouteProvider/BusinessRouteProvider";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {getBusinessId, getBusinessTheme} from "state/redux/slices/business/selectors";
import {orderActions} from "state/redux/slices/order";
import {incrementalIdEncrypt} from "utils/encryption/encodedIdDecrypt";
import {ServiceTypes} from "constants/index";
import {SmallGrayLaundryBagsIcon, MidCycleWashingMachineIcon} from "assets/images";
import {ITheme} from "types/theme";
import styles from "./Welcome.module.scss";

export const Welcome: React.FC = () => {
  const dispatch = useAppDispatch();
  const businessId = useAppSelector(getBusinessId);
  const history = useHistory();
  const theme = useAppSelector(getBusinessTheme) as ITheme;
  const [serviceType, setServiceType] = useState<ServiceTypes | null>(null);
  const cardsToDisplay = [
    {
      key: ServiceTypes.FULL_SERVICE,
      title: "Full-Service",
      icon: SmallGrayLaundryBagsIcon,
      altText: "small gray laundry bags icon meant to denote full-service selections",
      description:
        "We do your laundry! We offer both pickup and delivery, and drop-off service.",
    },
    {
      key: ServiceTypes.SELF_SERVICE,
      title: "Self-Service",
      icon: MidCycleWashingMachineIcon,
      altText: "mid cycle washing machine icon meant to denote a self-serve selection",
      description:
        "Do your own laundry! Use this app to pay for our machines, and track your turns.",
    },
  ];

  const onServiceTypeSelected = (incomingServiceType: ServiceTypes) => {
    setServiceType(incomingServiceType);
  };

  const handleNextButton = () => {
    dispatch(orderActions.resetOnlineOrderState({}));
    const path =
      serviceType === ServiceTypes.SELF_SERVICE
        ? `/business/${incrementalIdEncrypt(businessId)}/home`
        : `/order/business/${incrementalIdEncrypt(businessId)}?selectedFullService=true`;
    history.push(path);
  };

  const renderCards = () => {
    return cardsToDisplay.map((card) => {
      return (
        <A11yButton
          key={card.key}
          className={classNames(styles.serviceSelectionCard, {
            [styles.active]: serviceType === card.key,
          })}
          onAction={() => {
            onServiceTypeSelected(card.key);
          }}
          aria-pressed={serviceType === card.key}
        >
          <div className={styles.serviceSelectionImageAndTextContainer}>
            <div className={styles.serviceSelectionImageContainer}>
              <img src={card.icon} alt={card.altText} />
            </div>
            <div className={styles.serviceSelectionTextContainer}>
              <h2 className={styles.specificServiceHeaderText}>{card.title}</h2>
              <h4 className={styles.specificServiceDescriptionText}>
                {card.description}
              </h4>
            </div>
          </div>
        </A11yButton>
      );
    });
  };

  return (
    <BusinessRouteProvider>
      <div className={styles.pageLayout}>
        <div className={styles.pageFlexContainer}>
          {/* page content */}
          <div className={styles.bodyContainer}>
            <div className={styles.logoContainer}>
              <CustomLogo initialTheme={theme} />
            </div>
            <div className={styles.serviceSelectionContainer}>
              <div className={styles.welcomeScreenHeaderContainer}>
                <h1 className={styles.welcomeText}>Select Service Type</h1>
              </div>
              <div className={styles.serviceSelectionCardContainer}>{renderCards()}</div>
            </div>
          </div>

          {/* footer */}
          <div className={styles.footer}>
            {serviceType && (
              <button className={styles.nextButton} onClick={handleNextButton}>
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </BusinessRouteProvider>
  );
};
