import {FC, useState} from "react";
import {Button, TextField, Typography} from "@material-ui/core";
import {toast} from "react-toastify";
import {Image} from "rebass";
import {Drawer} from "components/common/drawers/Drawer";
import {INPUT_TIP_REGEXP, UPDATE_TIP_REGEXP} from "constants/constants";
import theme from "constants/themes/theme";
import {EN_LOCALE} from "locales/en";
import {IllustrationDelivery} from "assets/images";
import ToastError from "../ToastError";
import {tipSelectionStyles} from "./styles";

interface CustomTipDockModalProps {
  isOpen: boolean;
  toggle: () => void;
  onUpdate: (tip: string | null) => void;
}

const CustomTipDockModal: FC<CustomTipDockModalProps> = ({isOpen, toggle, onUpdate}) => {
  const classes = tipSelectionStyles();
  const [tip, setTip] = useState("");

  const onSetTip = (value: string) => {
    INPUT_TIP_REGEXP.test(value) && setTip(value);
  };

  const onUpdateClick = () => {
    const fixedValue = Number(tip.trim()).toFixed(2).replace(",", ".");

    if (!UPDATE_TIP_REGEXP.test(fixedValue) || Number(fixedValue) < 0 || !tip.length) {
      toast.error(<ToastError message={"Invalid tip."} />);
      return;
    }

    const stringValue = `$${fixedValue}`;
    onUpdate(stringValue);
    toggle();
  };

  return (
    <Drawer isShowingDrawer={isOpen} closeDrawer={toggle} title="Add a Tip">
      <div className={classes.addTipModalWrapper}>
        <div className={classes.addTipContent}>
          <div className={classes.imageWrapper}>
            <Image src={IllustrationDelivery}></Image>
          </div>
          <Typography className={classes.addTipDescriptionText}>
            100% of your tip will be given to staff involved in making sure your garments
            are picked up, cleaned, and delivered back to you.
          </Typography>
          <label>
            <Typography className={classes.tipTextHeader}>
              {EN_LOCALE.messages.howMuchTip}
            </Typography>
            <TextField
              className={classes.inputTypeNum}
              color="primary"
              variant="outlined"
              fullWidth
              autoFocus
              value={tip}
              onChange={(event) => onSetTip(event.target.value)}
              type="number"
              lang="en-US"
              InputProps={{
                lang: "en-US",
                startAdornment: <div className={classes.inputAdornment}>$</div>,
              }}
              inputProps={{
                style: {
                  color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
                  fontSize: 18,
                  lineHeight: "125%",
                  fontWeight: 400,
                },
                lang: "en-US",
                inputMode: "decimal",
                pattern: "[0-9]{0,3}([.][0-9]{1,2})?",
              }}
            />
          </label>
        </div>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onUpdateClick}
          className={classes.updateTipButton}
        >
          Update Tip
        </Button>
      </div>
    </Drawer>
  );
};

export default CustomTipDockModal;
