import React, {useEffect, useState} from "react";
import {
  ThemeProvider as MaterialThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import isFunction from "lodash/isFunction";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import {ThemeProvider} from "styled-components";
import ToastError from "components/common/ToastError";
import {typography} from "components/online-order/orderTheme";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions, orderSelectors} from "state/redux/slices/order";
import {getFilterClass, getStoreTheme, fixThemeSettings} from "utils/theme";
import {fetchStoreSettings} from "api/order";
import theme from "constants/themes/theme";
import {GlobalStyle, Loader} from ".";

const WithOrderStoreTheme = (props) => {
  const dispatch = useAppDispatch();
  const {orderToken, children, serviceOrderId} = props;

  const newOrderStateTheme = useAppSelector(orderSelectors.getTheme);

  const [storeSettings, setStoreSettings] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const storeRes = await fetchStoreSettings(orderToken, serviceOrderId);
        const fixedTheme = fixThemeSettings(storeRes?.data?.theme);
        setStoreSettings({
          ...storeRes?.data,
          theme: {
            ...fixedTheme,
          },
        });
        dispatch(orderActions.setTheme(fixedTheme));
      } catch (error) {
        if (error?.response?.status !== 401) {
          toast.error(<ToastError message={"Store settings could not be applied"} />);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [orderToken, serviceOrderId]);

  const filterClass = getFilterClass(
    newOrderStateTheme?.primaryColor || storeSettings?.theme?.primaryColor
  );

  const currentTheme = newOrderStateTheme ? {theme: newOrderStateTheme} : storeSettings;
  const storeTheme = getStoreTheme(theme, currentTheme);

  return (
    <MuiThemeProvider theme={createTheme({...storeTheme, ...typography, filterClass})}>
      <MaterialThemeProvider theme={createTheme({...storeTheme, filterClass})}>
        <ThemeProvider theme={storeTheme}>
          {/* Reset the global styles to take the primary font from store settings */}
          <GlobalStyle />
          {loading ? (
            <Loader />
          ) : isFunction(children) ? (
            children(storeSettings)
          ) : (
            children
          )}
        </ThemeProvider>
      </MaterialThemeProvider>
    </MuiThemeProvider>
  );
};

WithOrderStoreTheme.propTypes = {
  orderToken: PropTypes.string.isRequired,
  serviceOrderId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default WithOrderStoreTheme;
