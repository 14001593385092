export const getLaundryCategoryHeight = (laundryServicesAmount: number) => {
  const containerPadding = 16;
  const defaultContentHeight = 61;
  const bagsCounterHeight = 40;
  const servicesTitle = 48;
  const serviceHeight = 102;

  return (
    containerPadding * 2 +
    defaultContentHeight +
    bagsCounterHeight +
    servicesTitle +
    serviceHeight * laundryServicesAmount
  );
};
