import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {Loader} from "components/common";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {businessSelectors, businessThunks} from "state/redux/slices/business";
import {FetchingStatus} from "types/common";

export const BusinessRouteProvider = ({children}: {children: React.ReactNode}) => {
  const dispatch = useAppDispatch();
  const {businessId: encodedBusinessId} = useParams<{businessId?: string}>();

  const businessThemeFetchingStatus = useAppSelector(
    businessSelectors.getBusinessThemeFetchingStatus
  );

  useEffect(() => {
    const fetchBusinessData = async () => {
      const {businessId} = await dispatch(
        businessThunks.setBusinessThemeThunk(encodedBusinessId)
      ).unwrap();
      dispatch(businessThunks.setBusinessSettingsThunk(businessId));
    };

    if (encodedBusinessId && businessThemeFetchingStatus === FetchingStatus.Initial) {
      fetchBusinessData();
    }
  }, [dispatch, encodedBusinessId, businessThemeFetchingStatus]);

  return businessThemeFetchingStatus === FetchingStatus.Fulfilled ? (
    <>{children}</>
  ) : (
    <Loader />
  );
};
