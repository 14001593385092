import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {useHookstate} from "@hookstate/core";
import {captureException} from "@sentry/browser";
import debounce from "lodash/debounce";
import {useSessionStorage} from "usehooks-ts";
import {MaskedInput} from "components/common";
import {
  OPT_OUT_STATE_KEY,
  OptOutInfoBanner,
  OptOutInfoBannerType,
} from "components/common/OptOutInfoBanner";
import {Button, ButtonVariant} from "components/common/buttons/Button";
import {CustomerCard} from "components/verify-account/PhoneNumberScreen/CustomerCard";
import {SignUpForm} from "components/verify-account/PhoneNumberScreen/SignUpForm";
import useSkipOTP from "hooks/useSkipOTP";
import {onlineOrderState} from "state/online-order";
import {createErrorToast} from "utils/notifications/createErrorToast";
import {getCurrentBusinessAssets} from "utils/ooboHelpers";
import {requestOtp} from "api/auth";
import {verifyCustomer} from "api/customer";
import {
  EMAIL_REGEXP,
  IMPORTED_PHONE_NUMBER_DIGITS_LENGTH,
  NOT_DIGITS_REGEXP,
  PHONE_LENGTH,
  PHONE_NUMBER_PREFIX,
} from "constants/constants";
import {PHONE_NUMBER_INPUT_ID} from "constants/formLabelId";
import {EN_LOCALE} from "locales/en";
import {NewCustomer} from "../types";
import styles from "./phoneNumberScreen.module.scss";

interface PhoneNumberScreenProps {
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setOTP: Dispatch<SetStateAction<string>>;
  storeId: number | null;
  onlineOrderBusinessId: number | null;
  onSendVerificationClick: (newCustomerData: NewCustomer | null) => void;
}

export interface VerifiedCustomer {
  firstName: string | null;
  lastName: string | null;
  email: string;
  isVerified: boolean;
  optOutMarketingSms: boolean;
}

export const PhoneNumberScreen = ({
  onlineOrderBusinessId,
  setPhoneNumber,
  phoneNumber,
  onSendVerificationClick,
  setLoading,
  setOTP,
  storeId,
}: PhoneNumberScreenProps) => {
  const phoneNumberInputRef = useRef<HTMLInputElement | null>(null);
  const isAuthorized = useHookstate(onlineOrderState.isAuthorized).value;
  const {isOOBOEnabled, isOOBOUser, isSkipPhoneEntryAsKnownUser, presetOTPCode} =
    useSkipOTP();
  const [verifiedCustomer, setVerifiedCustomer] = useState<VerifiedCustomer | null>(null);
  const [isPresettedPhoneNumber, setIsPresettedPhoneNumber] = useState<boolean>(false);
  const [{firstName, lastName, email, optOutMarketingSms}, setState] = useState<
    Omit<VerifiedCustomer, "isVerified">
  >({
    firstName: "",
    lastName: "",
    email: "",
    optOutMarketingSms: false,
  });
  const [isCurrentVisibleOptOutInfoBanner, setIsCurrentVisibleOptOutInfoBanner] =
    useSessionStorage<boolean | null>(OPT_OUT_STATE_KEY, null);

  const verifyPhoneNumber = useCallback(
    async (value: string) => {
      try {
        setLoading(true);
        const {data} = await verifyCustomer({phoneNumber: value});
        setIsCurrentVisibleOptOutInfoBanner(data?.optOutUpdatesSms);
        if (!isOOBOUser) {
          setVerifiedCustomer(data ?? {});
        }
      } catch (error) {
        createErrorToast({
          primaryMessage: EN_LOCALE.messages.accountLinkingIssue,
        });
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setIsCurrentVisibleOptOutInfoBanner, isOOBOUser]
  );

  useEffect(() => {
    if (phoneNumber?.length === PHONE_LENGTH) {
      verifyPhoneNumber(phoneNumber);
      phoneNumberInputRef.current?.blur();
    }
  }, [verifyPhoneNumber, phoneNumber]);

  const onPhoneNumberChange = ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
    setVerifiedCustomer(null);
    setState((prevState) => ({...prevState, lastName: "", firstName: ""}));
    const formattedPhoneNumber = value
      .replace(NOT_DIGITS_REGEXP, "")
      .slice(0, PHONE_LENGTH);
    if (formattedPhoneNumber.length <= PHONE_LENGTH) {
      setPhoneNumber(
        value.replace(NOT_DIGITS_REGEXP, "").slice(0, PHONE_LENGTH) as string
      );
    }
  };

  const sendVerificationHandler = async () => {
    onSendVerificationClick(
      verifiedCustomer?.isVerified
        ? null
        : {
            fullName: `${firstName} ${lastName}`.trim(),
            phoneNumber,
            email,
            optOutMarketingSms: !optOutMarketingSms,
          }
    );
    if (isAuthorized) {
      setLoading(true);
      try {
        const res = await requestOtp({phoneNumber, storeId, isAuthorized});
        setOTP(res.data.otpCode);
      } catch (error) {
        captureException(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const onHandleChange = (
    {target: {value, name}}: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setState((prevState) => ({
      ...prevState,
      [name]: checked ?? value.replace(/[\d]+$/i, ""),
    }));
  };

  const validateEmail = (value: string) => value === "" || EMAIL_REGEXP.test(value);
  const isValidEmail = validateEmail(email);
  const [showInvalidEmailStatus, setShowInvalidEmailStatus] = useState(false);

  const setDebouncedEmailInvalidation = useCallback(
    debounce(() => {
      setShowInvalidEmailStatus(true);
    }, 700),
    []
  );

  const onEmailChanged = ({
    target: {value},
  }: ChangeEvent<HTMLTextAreaElement & HTMLInputElement>) => {
    setState((prevState) => ({...prevState, email: value.trim()}));

    if (!validateEmail(value)) {
      setDebouncedEmailInvalidation();
    } else {
      setDebouncedEmailInvalidation.cancel();
      setShowInvalidEmailStatus(false);
    }
  };

  const cleanEmail = () => {
    setShowInvalidEmailStatus(false);
    setState((prevState) => ({...prevState, email: ""}));
  };

  useEffect(() => {
    if (isSkipPhoneEntryAsKnownUser) {
      const {customerPhone} = getCurrentBusinessAssets();
      if ((phoneNumber || customerPhone) && isOOBOEnabled) {
        !phoneNumber && setPhoneNumber(customerPhone as string);
        setIsPresettedPhoneNumber(() => true);
      }
    }
  }, [isOOBOEnabled, isOOBOUser, phoneNumber, storeId]);

  useEffect(() => {
    const isImportedPhoneNumber =
      phoneNumber.length === IMPORTED_PHONE_NUMBER_DIGITS_LENGTH &&
      phoneNumber[0] === PHONE_NUMBER_PREFIX;
    if (
      isPresettedPhoneNumber &&
      phoneNumber &&
      (phoneNumber.length === PHONE_LENGTH || isImportedPhoneNumber)
    ) {
      presetOTPCode({
        phoneNumber,
        storeId,
        setOTP,
      });
      onSendVerificationClick(null);
    }
  }, [isPresettedPhoneNumber]);

  return (
    <div className={styles.wrapper}>
      <label htmlFor={PHONE_NUMBER_INPUT_ID} className={styles.phoneNumberLabel}>
        {EN_LOCALE.messages.phoneNumberCTA}
      </label>
      <MaskedInput
        id={PHONE_NUMBER_INPUT_ID}
        onChange={onPhoneNumberChange}
        value={phoneNumber || ""}
        mask="( 9 9 9 ) 9 9 9 - 9 9 9 9"
        type="tel"
        customRef={phoneNumberInputRef}
        className={styles.phoneInput}
        aria-label={EN_LOCALE.messages.phoneNumberInput}
      />
      {!verifiedCustomer && onlineOrderBusinessId && (
        <p className={styles.description}>{EN_LOCALE.messages.phoneNumberPurpose}</p>
      )}
      {verifiedCustomer && (
        <div className={styles.customerDataWrapper}>
          {!verifiedCustomer?.isVerified && (
            <SignUpForm
              firstName={firstName}
              lastName={lastName}
              email={email}
              optOutMarketingSms={optOutMarketingSms}
              showInvalidEmailStatus={showInvalidEmailStatus}
              onHandleChange={onHandleChange}
              onEmailChanged={onEmailChanged}
              setState={setState}
              cleanEmail={cleanEmail}
            />
          )}
          {isCurrentVisibleOptOutInfoBanner ? (
            <>
              <OptOutInfoBanner type={OptOutInfoBannerType.badge} />
              <Button
                onClick={() => verifyPhoneNumber(phoneNumber)}
                variant={ButtonVariant.UNDERLINED}
              >
                {EN_LOCALE.messages.optInCall}
              </Button>
            </>
          ) : (
            <CustomerCard
              verifiedCustomer={verifiedCustomer}
              isAuthorized={isAuthorized}
              isOOBOEnabled={isOOBOEnabled}
              firstName={firstName}
              lastName={lastName}
              isValidEmail={isValidEmail}
              sendVerificationHandler={sendVerificationHandler}
            />
          )}
        </div>
      )}
    </div>
  );
};
