import axios from "axios";
import {BASE_URL, BUSINESS_OWNER_AUTH_TOKEN_KEY, CUSTOMER_AUTH_TOKEN_KEY} from "utils";
import {handleLogout} from "utils/auth/handleLogout";
import {reissueToken} from "api/auth/reissueToken";
import {ACCESS_TOKEN_KEY} from "constants/auth";
import {
  ORDER_IS_NOT_ASSOCIATED,
  TOKEN_IS_EXPIRED,
} from "constants/errors/validationErrors";
import {ORIGIN} from "constants/index";

export const getCustomerAuthToken = () => {
  try {
    return JSON.parse(localStorage.getItem(CUSTOMER_AUTH_TOKEN_KEY) || "null");
  } catch (error) {
    console.log(error);
    return null;
  }
};

const getManagerAuthToken = () => {
  const managerAuthToken = sessionStorage.getItem(BUSINESS_OWNER_AUTH_TOKEN_KEY);
  return managerAuthToken && managerAuthToken !== "null" ? managerAuthToken : null;
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const managerAuthToken = getManagerAuthToken();
    config.headers = {
      ...config.headers,
      version: import.meta.env.VITE_VERSION,
      fwdorigin: ORIGIN,
    };

    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (accessToken) {
      // auth2 flow
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      // deprecated
      config.headers.customerAuthToken = getCustomerAuthToken();

      if (managerAuthToken) {
        config.headers.managerauthtoken = managerAuthToken;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const res = error.response;

    if (res?.status === 401) {
      if (res.data.error === TOKEN_IS_EXPIRED) {
        // if auth2 handles error about access token expiration happened
        // it reissues tokens pair and repeat request
        return reissueToken().then(
          (response) => {
            const {accessToken} = response.data;
            localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
            error.config.headers["Authorization"] = "Bearer " + accessToken;
            error.config.baseURL = undefined;

            return axiosInstance.request(error.config);
          },
          () => handleLogout() // logout user when refresh token is also expired
        );
      } else if (![ORDER_IS_NOT_ASSOCIATED].includes(res.data.error)) {
        // condition in order to continue show error without redirecting
        // when user tries open order page with incorrect phone number
        const token = res.config?.params?.token;
        setTimeout(() => {
          handleLogout({token});
        }, 1000);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
