import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchBusinessThemeByUniqueCode} from "../api/selfServe";
import {SELF_SERVE_REDUCER, BUSINESS_THEME_THUNK} from "../constants/selfServeGeneral";

export const getBusinessThemeByUniqueCode = createAsyncThunk(
  `${SELF_SERVE_REDUCER}/${BUSINESS_THEME_THUNK}`,
  async (uniqueCode: string) => {
    const response = await fetchBusinessThemeByUniqueCode(uniqueCode);
    return response.data.theme;
  }
);
