import {createSelector} from "reselect";
import {getOnlineOrderData} from "../selectors";

export const getTransportationInfo = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.transportation
);

export const getTransportationPreference = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.transportation.settings.transportationPreference
);
