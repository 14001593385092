import {useMemo, useState} from "react";
import {withLDConsumer} from "launchdarkly-react-client-sdk";

import useToggle from "hooks/useToggle";

import {orderItemCategories} from "components/order-summary/constants";

import {CurrentOrAllRecurringOrdersChoice} from "components/common";
import ServiceDockModal from "components/common/order-sections/service-selection/ServiceDockModal";
import {SeePricing} from "components/common/order-sections";
import ServiceModifiersDockModal from "components/newOrder/common/ServiceModifiers/ServiceModifiersDockModal";

const fetchModifiersForService = ({
  services,
  servicePriceId,
  isAllowFixedPriceServices,
}) => {
  const selectedService = services?.find(
    (service) => service.prices[0].id === servicePriceId
  );
  if (
    isAllowFixedPriceServices ||
    selectedService?.serviceCategory?.category === orderItemCategories.perPoundItem
  ) {
    return {
      modifiers: selectedService?.serviceModifiers?.map((modifier) => {
        return {
          id: modifier.id,
          name: modifier.modifier.name,
          price: modifier.modifier.price,
          pricingType: modifier.modifier.pricingType,
        };
      }),
      selectedService,
    };
  } else {
    return {modifiers: [], selectedService: {}};
  }
};

const ServiceSelection = (props) => {
  const {
    flags,
    isOpen,
    toggle,
    services,
    shouldShowCurrentOrRecurringChoice,
    servicePriceId,
    modifierIds,
    onServiceAndModifiersChange,
    storeId,
    postalCode,
  } = props;

  const [orderServicePriceIdState, setOrderServicePriceIdState] =
    useState(servicePriceId);
  const [orderServiceModifierIdsState, setOrderServiceModifierIdsState] =
    useState(modifierIds);
  const {isOpen: addOnModal, toggle: toggleAddOnModal} = useToggle();
  const {isOpen: subscriptionModal, toggle: toggleSubscriptionModal} = useToggle();
  const {isOpen: showPricing, toggle: toggleShowPricing} = useToggle();

  const isAllowFixedPriceServices = flags.fixedPriceModifiers;

  const {modifiers, selectedService} = useMemo(
    () =>
      fetchModifiersForService({
        services,
        servicePriceId: orderServicePriceIdState,
        isAllowFixedPriceServices,
      }),
    [orderServicePriceIdState, services, isAllowFixedPriceServices]
  );

  const setServicePriceId = (service) => {
    const {
      prices: [{id: priceId}],
    } = service;
    // If there is no change in the selected service modifierIds are not reset
    if (priceId !== orderServicePriceIdState) {
      setOrderServiceModifierIdsState([]);
    }
    setOrderServicePriceIdState(priceId);
    if (
      fetchModifiersForService({
        services,
        servicePriceId: priceId,
        isAllowFixedPriceServices,
      })?.modifiers?.length
    ) {
      toggleAddOnModal();
    }
  };

  const onSubscriptionUpdate = (choice) => {
    onSubmit(choice);
    toggleSubscriptionModal();
  };

  const handleSave = () => {
    if (shouldShowCurrentOrRecurringChoice) {
      toggleSubscriptionModal();
    } else {
      onSubmit();
    }
  };

  const onSubmit = (choice = null) => {
    toggle();
    onServiceAndModifiersChange(
      orderServicePriceIdState,
      orderServiceModifierIdsState,
      choice
    );
  };

  return (
    <>
      {services?.length ? (
        <ServiceDockModal
          isOpen={!!isOpen}
          toggle={toggle}
          services={services}
          selectedServicePriceId={orderServicePriceIdState}
          selectedModifiersId={orderServiceModifierIdsState}
          setServicePriceId={setServicePriceId}
          handleSave={handleSave}
          toggleShowPricing={toggleShowPricing}
        />
      ) : null}

      <ServiceModifiersDockModal
        service={selectedService}
        modifiers={modifiers}
        selectedModifiersIds={orderServiceModifierIdsState || []}
        isOpen={!!(modifiers?.length && addOnModal)}
        toggle={toggleAddOnModal}
        onSubmit={(modifierIds) => {
          setOrderServiceModifierIdsState(modifierIds);
          toggleAddOnModal();
        }}
      />

      <CurrentOrAllRecurringOrdersChoice
        isOpen={shouldShowCurrentOrRecurringChoice && subscriptionModal}
        toggle={toggleSubscriptionModal}
        header="Edit Services"
        onSubmit={onSubscriptionUpdate}
      />
      <SeePricing
        showPricing={showPricing}
        toggleShowPricing={toggleShowPricing}
        storeId={storeId}
        postalCode={postalCode}
      />
    </>
  );
};

export default withLDConsumer()(ServiceSelection);
