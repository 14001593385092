import {httpClient} from "api";
import {AxiosResponse} from "axios";
import {IAddTimePayload, IMachine, ISelfServeOrder, ITheme} from "../types";

export const fetchMachineDataByUniqueCode: (
  uniqueCode: string
) => Promise<AxiosResponse<IMachine>> = async (uniqueCode) => {
  return httpClient({
    method: "GET",
    url: `/live-status/machine/${uniqueCode}/details-by-qr-code`,
  });
};

export const fetchBusinessThemeByUniqueCode: (
  uniqueCode: string
) => Promise<AxiosResponse<{success: boolean; theme: ITheme}>> = async (uniqueCode) => {
  return httpClient({
    method: "GET",
    url: `/live-status/machine/${uniqueCode}/business-theme-by-qr-code`,
  });
};

export const fetchSendSelfServeOrder: (data: ISelfServeOrder) => Promise<
  AxiosResponse<
    {
      success: boolean;
      centsCustomerId: number;
    },
    any
  >
> = async ({
  machineId,
  topOffQuantity,
  topOffPriceInCents,
  basePriceInCents,
}: ISelfServeOrder) => {
  return httpClient({
    method: "POST",
    url: `/live-status/machine/${machineId}/run`,
    data: {
      topOffQuantity,
      basePriceInCents,
      topOffPriceInCents,
    },
  });
};

export const addTimeToMachine: (data: IAddTimePayload) => Promise<
  AxiosResponse<
    {
      success: boolean;
    },
    any
  >
> = async ({
  machineId,
  deviceId,
  serviceType,
  topOffPriceInCents,
  topOffQuantity,
  topOffTimeInSeconds,
  turnId,
}: IAddTimePayload) => {
  return httpClient({
    method: "POST",
    url: `/live-status/machine/${machineId}/turn/add-time`,
    data: {
      deviceId,
      serviceType,
      topOffPriceInCents,
      topOffQuantity,
      topOffTimeInSeconds,
      turnId,
    },
  });
};
