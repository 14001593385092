import {useState, type FC, useEffect} from "react";
import {Button} from "components/common/buttons/Button";
import {Drawer} from "components/common/drawers/Drawer";
import ModifierButton from "components/newOrder/common/ServiceModifiers/ModifierButton";
import {getModifierPriceString, getPriceString} from "components/online-order/utils";
import {useThemeValues} from "hooks/useThemeValues";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {
  getIsModifiersDrawerOpened,
  getSelectedModifiers,
  getSelectedService,
} from "state/redux/slices/order/selectors/servicesSelector";
import styles from "./modifiersDrawer.module.scss";

/**
 * @deprecated Use src/components/common/drawers/ModifiersDrawer/ModifiersDrawer.tsx instead
 */
export const ModifiersDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const selectedService = useAppSelector(getSelectedService);
  const selectedModifiers = useAppSelector(getSelectedModifiers);
  const isModifiersDrawerOpened = useAppSelector(getIsModifiersDrawerOpened);
  const {translucentPrimaryColor} = useThemeValues();
  const [modifiersToUpdate, setModifiersToUpdate] = useState(
    selectedService?.serviceModifiers || []
  );
  const servicePrice =
    selectedService?.serviceCategory && selectedService?.prices
      ? getPriceString({
          serviceCategory: selectedService.serviceCategory,
          prices: selectedService.prices,
        })
      : "";

  const formattedModifiers = selectedService?.serviceModifiers?.map((modifier) => {
    return {
      id: modifier.id,
      name: modifier.modifier.name,
      price: modifier.modifier.price,
      pricingType: modifier.modifier.pricingType,
    };
  });

  const onModifierToggle = (modifierId: number) => {
    const currentModifier = selectedService?.serviceModifiers?.find(
      ({id}) => id === modifierId
    );

    if (currentModifier) {
      const isSelected = Boolean(modifiersToUpdate?.find(({id}) => id === modifierId));
      if (isSelected && modifiersToUpdate?.length) {
        setModifiersToUpdate((prevModifiers) =>
          prevModifiers.filter(({id}) => id !== modifierId)
        );
      } else {
        setModifiersToUpdate((prevModifiers) => [...prevModifiers, currentModifier]);
      }
    }
  };

  const onModifiersSave = () => {
    dispatch(orderActions.setSelectedModifiers(modifiersToUpdate));
    dispatch(orderActions.toggleModifiersDrawer());
  };

  useEffect(() => {
    if (isModifiersDrawerOpened) {
      setModifiersToUpdate(selectedModifiers || []);
    }
  }, [isModifiersDrawerOpened, selectedModifiers]);

  return (
    <Drawer
      isShowingDrawer={isModifiersDrawerOpened}
      closeDrawer={() => dispatch(orderActions.toggleModifiersDrawer())}
      title="Add-ons"
    >
      <div className={styles.drawer}>
        <div className={styles.serviceHeaderContainer}>
          <div className={styles.serviceTitleContainer}>
            <h4 className={styles.serviceTitle}>{selectedService?.name}</h4>
            <h4 className={styles.servicePrice}>{servicePrice}</h4>
          </div>
          {selectedService?.description && (
            <p className={styles.serviceDescription}>{selectedService.description}</p>
          )}
        </div>
        <div style={{backgroundColor: translucentPrimaryColor}}>
          <h2 className={styles.addonsHeader}>Choose Add-Ons</h2>
        </div>
        <div className={styles.addonsContainer}>
          {formattedModifiers
            ?.sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(({name, price, pricingType, id}) => (
              <ModifierButton
                key={id}
                price={`+ $${getModifierPriceString({price, pricingType})}`}
                name={name}
                checked={modifiersToUpdate?.map(({id}) => id)?.includes(id)}
                onClick={() => onModifierToggle(id)}
              />
            ))}
        </div>
        <div className={styles.buttonContainer}>
          <Button onClick={onModifiersSave}>Save</Button>
        </div>
      </div>
    </Drawer>
  );
};
