import {createSelector} from "reselect";
import {RootState} from "state/redux/store";

const getSelfServeData = (state: RootState) => state.selfServe;
export const getBusinessTheme = createSelector(getSelfServeData, (state) => state.theme);

export const selfServeSelectors = {
  getSelfServeData,
  getBusinessTheme,
};
