import {
  AddCreditData,
  AddPromoData,
  CancelOrderData,
  GetRouteDeliveryData,
  SetOnlineOrderReturnMethodData,
  UpdatePaymentMethodIntentData,
} from "types";
import httpClient from "api/httpClient";
import {PARAMS_KEYS} from "constants/api";
import {CANCELLATION_TYPES} from "constants/order";
import {RecurringOrderSettingsOrigin} from "constants/recurringOrder";

export const fetchStoreSettings = (
  token: string | undefined,
  serviceOrderId?: string
) => {
  return httpClient({
    method: "GET",
    url: "/live-status/settings",
    params: {
      token,
      [PARAMS_KEYS.serviceOrderId]: serviceOrderId,
    },
  });
};

export const authenticateOrder = (token: string | undefined, serviceOrderId?: string) => {
  return httpClient({
    method: "GET",
    url: "/live-status/verify-order",
    params: {
      token,
      [PARAMS_KEYS.serviceOrderId]: serviceOrderId,
    },
  });
};

export const fetchOrderDetail = (token: string | undefined, serviceOrderId?: string) => {
  return httpClient({
    method: "GET",
    url: "/live-status",
    params: {
      token,
      [PARAMS_KEYS.serviceOrderId]: serviceOrderId,
    },
  });
};

export const updateTip = (
  token: string | undefined,
  tipData: any,
  serviceOrderId?: string
) => {
  return httpClient({
    method: "PATCH",
    url: "/live-status/tip",
    params: {
      token,
      [PARAMS_KEYS.serviceOrderId]: serviceOrderId,
    },
    data: tipData,
  });
};

export const addPromo = (
  token: string | undefined,
  promoData: AddPromoData,
  serviceOrderId?: string
) => {
  return httpClient({
    method: "PATCH",
    url: "/live-status/add-promotion",
    params: {
      token,
      [PARAMS_KEYS.serviceOrderId]: serviceOrderId,
    },
    data: promoData,
  });
};

export const removePromo = (token: string | undefined, serviceOrderId?: string) => {
  return httpClient({
    method: "PATCH",
    url: "/live-status/remove-promotion",
    params: {
      token,
      [PARAMS_KEYS.serviceOrderId]: serviceOrderId,
    },
  });
};

export const addCredits = (
  token: string | undefined,
  creditsData: AddCreditData,
  serviceOrderId?: string
) => {
  return httpClient({
    method: "PATCH",
    url: "/live-status/add-credits",
    params: {
      token,
      [PARAMS_KEYS.serviceOrderId]: serviceOrderId,
    },
    data: creditsData,
  });
};

export const removeCredits = (token: string | undefined, serviceOrderId?: string) => {
  return httpClient({
    method: "PATCH",
    url: "/live-status/remove-credits",
    params: {
      token,
      [PARAMS_KEYS.serviceOrderId]: serviceOrderId,
    },
  });
};

export const setOnlineOrderReturnMethod = (
  token: string | undefined,
  data: SetOnlineOrderReturnMethodData,
  serviceOrderId?: string
) => {
  return httpClient({
    method: "PUT",
    url: "/live-status/return-method",
    params: {
      token,
      [PARAMS_KEYS.serviceOrderId]: serviceOrderId,
    },
    data,
  });
};

export const updatePaymentMethodIntent = ({
  orderToken,
  paymentToken,
  serviceOrderId,
}: UpdatePaymentMethodIntentData) => {
  return httpClient({
    method: "PUT",
    url: "/live-status/payment-method",
    params: {
      token: orderToken,
      [PARAMS_KEYS.serviceOrderId]: serviceOrderId,
    },
    data: {paymentToken},
  });
};

export interface ICancelOrderPayload {
  type: CANCELLATION_TYPES | string;
  message: string | null;
  recurringOrderSettingsOrigin?: RecurringOrderSettingsOrigin;
  teamMemberId?: number | null;
}

export const cancelOrder = (params: CancelOrderData, data: ICancelOrderPayload) => {
  return httpClient({
    method: "PATCH",
    url: "/live-status/order/cancel",
    params,
    data,
  });
};

export const getRouteDelivery = ({
  orderToken,
  orderDelivery,
  serviceOrderId,
}: GetRouteDeliveryData) => {
  return httpClient({
    method: "GET",
    url: `/live-status/order-delivery/${orderDelivery}/route-details`,
    params: {token: orderToken, [PARAMS_KEYS.serviceOrderId]: serviceOrderId},
  });
};

export const fetchOrderDeliveries = (
  token: string | undefined,
  serviceOrderId?: string
) => {
  return httpClient({
    method: "GET",
    url: `/live-status/order-deliveries`,
    params: {token, [PARAMS_KEYS.serviceOrderId]: serviceOrderId},
  });
};

export const manageOrder = (
  token: string | undefined,
  data: any,
  serviceOrderId?: string
) => {
  return httpClient({
    method: "PUT",
    url: `/live-status/live-link/manage`,
    params: {token, [PARAMS_KEYS.serviceOrderId]: serviceOrderId},
    data,
  });
};

export const fetchSelfServiceOrder = (id: number) => {
  return httpClient({
    method: "GET",
    url: `/live-status/machine/turns/${id}/details`,
  });
};
