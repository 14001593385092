import {createSelector} from "reselect";
import {FetchingStatus} from "types/common";
import {getOnlineOrderData} from "../selectors";

export const getOrderBuilderServices = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services
);

export const getBagsCount = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.bagsCount
);

export const getServicesPricesFetchingStatus = createSelector(
  getOnlineOrderData,
  (state) => state.apiRequests.servicesPrices.fetchingStatus
);

export const getLaundryServicesPrices = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.categories.laundry.servicesPrices
);

export const getDryCleaningServicesPrices = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.categories.dryCleaning.servicesPrices
);

export const getProductsServicesPrices = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.products.servicesPrices
);

export const getSelectedServiceCategories = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.selectedCategories
);

export const getCustomerSelectedServices = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.customerSelectedServices
);

export const getLastSelectedService = createSelector(
  getOnlineOrderData,
  (state) =>
    state.orderBuilder.services.customerSelectedServices[
      state.orderBuilder.services.customerSelectedServices.length - 1
    ]
);

/**
 * @deprecated Details: https://cents.atlassian.net/browse/DEL-725
 * Use `getCustomerSelectedServices` instead
 */
export const getSelectedService = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.selectedService
);

/**
 * @deprecated Details: https://cents.atlassian.net/browse/DEL-725
 * Use `getCustomerSelectedServices` instead
 */
export const getSelectedModifiers = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.selectedModifiers
);

export const getAvailableLaundryServices = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.categories.laundry.availableLaundryServices
);

export const getOnlineServicesFetchingStatus = createSelector(
  getOnlineOrderData,
  (state) => state.apiRequests.onlineOrderServices.fetchingStatus
);

export const getLaundryTurnAround = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.categories.laundry.turnAround
);

export const getDryCleaningTurnAround = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.categories.dryCleaning.turnAround
);

export const getServiceMultiSelectEnabled = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.serviceMultiselectEnabled
);

export const getIsServicesLoading = createSelector(
  getOnlineOrderData,
  (state) =>
    state.apiRequests.deliveryServices.fetchingStatus === FetchingStatus.Pending ||
    state.apiRequests.deliverySettings.fetchingStatus === FetchingStatus.Pending
);

export const getDeliverySettingsFetchingStatus = createSelector(
  getOnlineOrderData,
  (state) => state.apiRequests.deliverySettings.fetchingStatus
);

export const getDeliverySettings = createSelector(
  getOnlineOrderData,
  (state) => state.apiRequests.deliverySettings
);

export const getIsDryCleaningAvailableForDelivery = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.categories.dryCleaning.availableForDelivery
);

export const getIsModifiersDrawerOpened = createSelector(
  getOnlineOrderData,
  (state) => state.drawersStates.isModifiersDrawerOpened
);

export const getPossibleSelectedService = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.services.possibleSelectedService
);

export const getIsServicesDrawerOpened = createSelector(
  getOnlineOrderData,
  (state) => state.drawersStates.isServicesDrawerOpened
);
