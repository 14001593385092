import ServiceSelectionDockModalForm from "./EditServiceSelectionDockModal";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {getStoreId} from "state/redux/slices/order/selectors/storeSelectors";
import {orderActions, orderSelectors} from "state/redux/slices/order";

const EditServiceSelection = (props) => {
  const {isOpen, toggle, services} = props;
  const dispatch = useAppDispatch();

  const {
    orderBuilder: {
      services: {selectedService, selectedModifiers},
    },
  } = useAppSelector(orderSelectors.getOnlineOrderData);
  const servicePriceId = selectedService?.prices?.[0]?.id;
  const serviceModifiersIds = selectedModifiers?.map((modifier) => modifier.id);
  const storeId = useAppSelector(getStoreId);

  const handleServicesAndModifiersChange = (servicePriceId, modifierIds) => {
    const selectedService = services.find(
      (service) => service.prices[0].id === servicePriceId
    );
    const selectedModifiers = selectedService?.serviceModifiers?.filter((modifier) => {
      return modifierIds?.includes(modifier?.id);
    });

    dispatch(orderActions.setSelectedLaundryService(selectedService));
    dispatch(orderActions.setSelectedModifiers(selectedModifiers));
  };

  return (
    <ServiceSelectionDockModalForm
      isOpen={!!isOpen}
      toggle={toggle}
      services={services}
      servicePriceId={servicePriceId}
      modifierIds={serviceModifiersIds}
      onServiceAndModifiersChange={handleServicesAndModifiersChange}
      storeId={storeId}
    />
  );
};

export default EditServiceSelection;
