import {createSelector} from "reselect";
import {FETCHING_STATUS} from "constants/api";
import {getOnlineOrderData} from "../selectors";

/**
 * @deprecated Use src/state/redux/slices/business/selectors.ts instead
 */
export const getBusinessId = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.store.businessId
);

export const getStoreId = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.store.storeId
);

export const getStoreName = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.store.storeName
);
export const getStoreAddress = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.store.storeAddress
);

export const getIsFetchingStore = createSelector(
  getOnlineOrderData,
  (state) => state.nearStoresData.fetchingStatus === FETCHING_STATUS.PENDING
);

export const getIsInitialStoreStatus = createSelector(
  getOnlineOrderData,
  (state) => state.nearStoresData.fetchingStatus === FETCHING_STATUS.INITIAL
);

export const getStoreIdFromUrl = createSelector(
  getOnlineOrderData,
  (state) => state.initialOrderData.data.storeId
);

export const getIsFetchingOnlineOrderServices = createSelector(
  getOnlineOrderData,
  (state) =>
    state.apiRequests.onlineOrderServices.fetchingStatus === FETCHING_STATUS.PENDING
);

export const getOnlineOrderRequestFetchingStatus = createSelector(
  getOnlineOrderData,
  (state) => state.apiRequests.onlineOrderServices.fetchingStatus
);
