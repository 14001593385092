import {useAppSelector} from "state/redux/hooks";
import {getCustomerSelectedServices} from "state/redux/slices/order/selectors/servicesSelector";
import {ISelectedService} from "state/redux/slices/order/types/orderBuilder";
import {formatServicesListFromCustomerSelectedServices} from "utils/order/formatServicesListFromCustomerSelectedServices";

export const useSelectedServicesList = () => {
  const customerSelectedServices = useAppSelector(getCustomerSelectedServices);
  const servicesList = formatServicesListFromCustomerSelectedServices({
    customerSelectedServices,
  });

  const getServiceById = (id: ISelectedService["id"]) => {
    return customerSelectedServices.find((service) => service.id === id) || null;
  };

  return {servicesList, getServiceById};
};
